import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Modal, Form } from 'react-bootstrap';
import { DeleteComment, GetRequest, PostRequest, UpdateRatee } from '../utils/apiCall';

const CategoriesPage = () => {
  const [categories, setCategories] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState('create'); // 'create' or 'edit'
  const [currentCategory, setCurrentCategory] = useState({ name: '', description: '' });

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await GetRequest('/api/home/getCategories'); // Adjust the endpoint
      setCategories(response.data.data);
      setFilteredCategories(response.data.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
    if (query) {
      const filtered = categories.filter((category) =>
        category.name.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredCategories(filtered);
    } else {
      setFilteredCategories(categories);
    }
  };

  const handleShowModal = (type, category = { name: '', description: '' }) => {
    setModalType(type);
    setCurrentCategory(category);
    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);

  const handleSaveCategory = async () => {
    try {
      if (modalType === 'create') {
        await PostRequest(currentCategory, '/api/home/createCategory');
      } else {
        await UpdateRatee(currentCategory, `/api/home/updateCategory/${currentCategory._id}`);
      }
      fetchCategories();
      handleCloseModal();
    } catch (error) {
      console.error('Error saving category:', error);
    }
  };

  const handleDeleteCategory = async (id) => {
    try {
      await DeleteComment(`/api/home/deleteCategory/${id}`);
      fetchCategories();
    } catch (error) {
      console.error('Error deleting category:', error);
    }
  };

  return (
    <div className="container">
      <div className='d-flex py-3  justify-content-between'>
      <h1>Categories</h1>
      <Button variant="primary" className='py-1 px-3' onClick={() => handleShowModal('create')}>
        Create Category
      </Button>
      </div>

      {/* Search Bar */}
      <Form.Group className="mb-3" controlId="search">
        <Form.Control
          type="text"
          placeholder="Search categories"
          value={searchQuery}
          onChange={(e) => handleSearch(e.target.value)}
        />
      </Form.Group>

      {/* Create Category Button */}

      {/* Categories List */}
      <ul className="list-group mt-3">
        {filteredCategories.map((category) => (
          <li key={category._id} className="list-group-item d-flex justify-content-between align-items-center">
            <span>{category.name}</span>
            <div>
              <Button
                variant="info"
                className="me-2"
                onClick={() => handleShowModal('edit', category)}
              >
                Edit
              </Button>
              <Button variant="danger" onClick={() => handleDeleteCategory(category._id)}>
                Delete
              </Button>
            </div>
          </li>
        ))}
      </ul>

      {/* Create/Edit Category Modal */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{modalType === 'create' ? 'Create Category' : 'Edit Category'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="categoryName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                value={currentCategory.name}
                onChange={(e) => setCurrentCategory({ ...currentCategory, name: e.target.value })}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSaveCategory}>
            {modalType === 'create' ? 'Create' : 'Update'}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CategoriesPage;

