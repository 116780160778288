import React, { useEffect, useState } from "react";


import { useSelector } from "react-redux";
import { token } from "../redux/tokenSlice";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import UserDetailsModal from "../componenets/userDetails";
import { getAllRateeUsers } from "../utils/apiCall";
import { Link, useNavigate } from "react-router-dom";
import { userDetail } from "../redux/userDetailSlice";

function Reviews() {
  const [searchQuery, setSearchQuery] = useState("");
  const [allUsers, setAllUsers] = useState([]);
  // const [currentPage, setCurrentPage] = useState(1);
  // const [itemsPerPage] = useState(15); // Number of items per page
  const [selectedUser, setSelectedUser] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true); 
  const userToken = useSelector(token);
  const userType = useSelector(userDetail);
  const navigate = useNavigate();
  // const navigate = useNavigate();

  const getAllReviews = ()=>{
    setIsLoading(true);
     getAllRateeUsers(`/api/home/getMyRateeUsers/${userType._id}`)
      .then((response) => {
        if (response.status) {
          console.log('RateeUsers:', response.data);
        const sortedUsers = response.data.data.sort((a, b) => new Date(b.date_created) - new Date(a.date_created));
          setAllUsers(sortedUsers);
        } else {
          console.error('Error:', response.error);
        }
      })
      .catch((error) => {
        console.error('Error fetching users:', error);
      }).finally(() => {
        setIsLoading(false); // Set loading to false when API call completes (success or error)
      });
  }
  const getCurrentReviews = ()=>{
    setIsLoading(false);
     getAllRateeUsers(`/api/home/getMyRateeUsers/${userType._id}`)
      .then((response) => {
        if (response.status) {
          console.log('RateeUsers:', response.data);
          
        const sortedUsers = response.data.data.sort((a, b) => new Date(b.date_created) - new Date(a.date_created));
          setAllUsers(sortedUsers);
        } else {
          console.error('Error:', response.error);
        }
      })
      .catch((error) => {
        console.error('Error fetching users:', error);
      }).finally(() => {
        setIsLoading(false); // Set loading to false when API call completes (success or error)
      });
  }
  const formatDate = (dateString) => {
  const date = new Date(dateString);

  // Get date components
  const year = date.getFullYear();
  const month = date.toLocaleString("en-US", { month: "long" });
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, "0");

  // Determine AM/PM and convert hour to 12-hour format
  const ampm = hours >= 12 ? "pm" : "am";
  const hour12 = hours % 12 || 12;

  // Add suffix to day (st, nd, rd, th)
  const daySuffix = (day) => {
    if (day > 3 && day < 21) return "th"; // Covers 11th - 20th
    switch (day % 10) {
      case 1: return "st";
      case 2: return "nd";
      case 3: return "rd";
      default: return "th";
    }
  };

  // Format the final date string
  return `${month} ${day}${daySuffix(day)}, ${year}, ${hour12}:${minutes} ${ampm}`;
};


  // Fetch all users on component mount
  useEffect(() => {
    getAllReviews()
  }, [userToken]);

  // Handle search input change
  const handleSearchChange = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
    // setCurrentPage(1); // Reset to first page after search
  };

  // Filter users based on search query
  const filteredUsers = allUsers.filter((user) =>
    user.name.toLowerCase().includes(searchQuery)
  );


  // Handle user click
  const handleUserClick = (user) => {
    navigate(`/user/${user._id}`, { state: { user } });
  };

  // Close modal
  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedUser(null);
    getCurrentReviews();
  };

  // Render paginated user list
  const renderUsers = () => {
    // const paginatedUsers = paginate(filteredUsers);

    if (isLoading) {
      return (
        <div className="text-center mt-4 d-flex align-items-center justify-content-center" style={{height:500}}>
          <Spinner animation="border" role="status" variant="success">
          </Spinner>
        </div>
      );
    }
    if (filteredUsers.length === 0) {
      return (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ height: 380 }}
        >
          <p className="text-muted">No Review created yet.</p>
        </div>
      );
    }

    return (
      <ul className="list-group" style={filteredUsers.length < 7?{ height: 480 }:{}}>
        {filteredUsers.map((user) => (
          <li 
             key={user._id}
            className="list-group-item clickable"
            onClick={() => handleUserClick(user)}
            style={{cursor:"pointer"}}
          >
            <div>{user.name} <span style={{fontSize:14}}>({user.averageRating ?user.averageRating:5 })</span>
              <span style={{ fontSize: '0.8em', color: 'grey', marginLeft: '10px' }}>
                {formatDate(user.date_created)}
              </span> 
            </div>
            <div style={{fontSize:12}}>{user.institute?user.institute:"Institute name"}</div>
         </li>
          
        ))}
      </ul>
    );
  };

  return (
    <div className="container">
      <Row className="justify-content-left mt-4">
        <Col md={6}>
          <Form.Control
            type="text"
            placeholder="Search Reviews..."
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </Col>
        <Col md={3}>
          <Button as={Link} to={userToken.token != ""? "/create-user":"/login"} variant="primary">
            New Review
          </Button>
        </Col>
      </Row>
      <section className="card-sec my-3">
        <div className="row">
          <div className="col">{renderUsers()}</div>
        </div>
      </section>
      {selectedUser && (
        <UserDetailsModal
          show={showModal}
          handleClose={handleCloseModal}
          user={selectedUser}
          getAllReviews={getCurrentReviews}
        />
      )}
    </div>
  );
}

export default Reviews;
