import {createSlice} from '@reduxjs/toolkit';

export const userDetailSlice = createSlice({
  name: 'userDetail',
  initialState: {
    data:{
    },
    userRole:""
  },
  reducers: {
    user: (state, action) => {
      return action.payload;
    },
    userRole: (state, action) => {
      return action.payload;
    }
  },
});

export const { user, userRole} = userDetailSlice.actions;
export const userDetail = state => state.userDetail;

export default userDetailSlice.reducer;
