import axios from "axios";
import { baseURL } from "./appUris";

export const GetRequest = async (url) => {
  try {
    const res = await axios({
      method: "get",
      url: url,
      baseURL: baseURL,
      headers: {},
    });

    if (res.status === 200) {
      return { status: true, data: res.data };
    } else {
      return { status: false, data: res.data };
    }
  } catch (error) {
    return { status: false, data: null, error: error };
  }
};

export const GetMyForums = async (url , data) => {
  try {
    const res = await axios({
      method: "get",
      url: url,
      data:data,
      baseURL: baseURL,
      headers: {},
    });

    if (res.status === 200) {
      return { status: true, data: res.data };
    } else {
      return { status: false, data: res.data };
    }
  } catch (error) {
    return { status: false, data: null, error: error };
  }
};

export const RegisterPostRequest = async (data, url) => {
  const headers = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  };
  try {
    const res = await axios({
      method: 'post',
      url: url,
      baseURL: baseURL,
      data: data,
      headers: headers,
    });
    if (res.status === 200) {
      return { status: true, data: res.data };
    } else {
      return { status: false, data: res.data };
    }
  } catch (error) {
    return { status: false, data: null, error: error };
  }
};

export const PostRequest = async (data, url) => {
  const headers = {
    'Accept': 'application/json', 
    'Content-Type': 'application/json', 
  };
  try {
    const res = await axios({
      method: "post",
      url: url,
      baseURL: baseURL,
      data: data,
      headers: headers,
    });
    if (res.status === 200) {
      return { status: true, data: res.data };
    } else {
      return { status: false, data: res.data };
    }
  } catch (error) {
    return { status: false, data: null, error: error };
  }
};

export const UpdateRatee = async (data, url) => {
  const headers = {
    'Accept': 'application/json', 
    'Content-Type': 'application/json', 
  };
  try {
    const res = await axios({
      method: "put",
      url: url,
      baseURL: baseURL,
      data: data,
      headers: headers,
    });
    console.log(res);
    if (res.status) {
      return { status: true, data: res.data };
    } else {
      return { status: false, data: res.data };
    }
  } catch (error) {
    return { status: false, data: null, error: error };
  }
};

export const UpdateUser = async (data, url) => {

  try {
    const response = await axios.put(`${baseURL}${url}`, data, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    
    if (response.status === 200) {
      console.log("User updated successfully:", response.data);
      return { status: true, data: response.data };
    } else {
      console.log("Failed to update user:", response.data);
      return { status: false, data: response.data };
    }
  } catch (error) {
    console.error("Error updating user:", error.response ? error.response.data : error.message);
    return { status: false, data: null, error: error };
  }
};

export const GetAllUsers = async (url, token) => {
  console.log("user token get user ==> ",token, url);
  const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`, // Add the token here
  };
  
  try {
    const res = await axios({
      method: "post",
      url: url,
      baseURL: baseURL,
      headers: headers,
    });
    
    if (res.status === 200) {
      return { status: true, data: res.data };
    } else {
      return { status: false, data: res.data };
    }
  } catch (error) {
    return { status: false, data: null, error: error };
  }
};

export const getAllRateeUsers = async (url, token) => {
  const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`, // Add the token here
  };
  
  try {
    const res = await axios({
      method: "get",
      url: url,
      baseURL: baseURL, // Ensure baseURL is defined
      headers: headers,
    });
    
    if (res.status === 200) {
      return { status: true, data: res.data };
    } else {
      return { status: false, data: res.data };
    }
  } catch (error) {
    return { status: false, data: null, error: error };
  }
};

export const DeleteComment = async (url) => {
  try {
    const res = await axios({
      method: "delete",
      url: url,
      baseURL: baseURL,
      headers: {},
    });

    if (res.status === 200) {
      return { status: true, data: res.data };
    } else {
      return { status: false, data: res.data };
    }
  } catch (error) {
    return { status: false, data: null, error: error };
  }
};

export const updateForumStatus = async (forumId, newStatus) => {
    try {
        const response = await axios.put(`${baseURL}/api/home/forum/${forumId}/update-status`, {
            status: newStatus
        });
        return response.data;
    } catch (error) {
        console.error('Error updating forum status:', error);
        throw error;
    }
};