import React, { useState } from "react";
import { useNavigate, NavLink, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userDetail, userRole } from "../../redux/userDetailSlice";
import { token, updateToken } from "../../redux/tokenSlice";
import LogoutConfirmationModal from "../logoutModal";
import { Button, Col, Image, NavDropdown, Row } from "react-bootstrap";
import { FaUserCircle } from "react-icons/fa";
import { PiUserCircleFill } from "react-icons/pi";
import { FaRegCircleDot } from "react-icons/fa6";

export function Header() {
  document.body.style.backgroundColor = "#f5f5f5";
  const dispatch = useDispatch();
  const userType = useSelector(userDetail);
  const userToken = useSelector(token);
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);

  const handleLogout = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleConfirmLogout = () => {
    dispatch(updateToken(""));
    dispatch(userRole(""));
    navigate("/login");
    setShowModal(false);
  };
  const getNavLinkStyle = (isActive) => ({
    borderBottom: isActive ? "2px solid #000" : "none",
    color: "#000",
    textDecoration: "none",
    paddingBottom: "5px",
  });
  const navLinkStyle = {
    fontFamily: "Montserrat",
    color: "#333",
    textDecoration: "none",
    fontSize: 32,
    fontWeight: "bold",
  };

  return (
    <>
      <div className="d-flex flex-column flex-md-row align-items-center p-3 px-md-4  bg-white border-bottom box-shadow justify-content-between">
        <NavLink to="/" className="my-0 mr-md-auto " style={navLinkStyle}>
          Rate My Admin
        </NavLink>
        <div className="d-flex flex-column flex-md-row align-items-center">
          <nav className=" ml-auto my-2 ">
            {(userType.role === "ADMIN" || userType.role === "OPERATOR") &&
              userToken.token !== "" && (
                <NavLink
                  as={NavLink}
                  className="active-link p-2 text-dark "
                  to="/allUsers"
                  style={({ isActive }) => getNavLinkStyle(isActive)}
                >
                  All Users
                </NavLink>
              )}
            <NavLink
              as={NavLink}
              className="active-link p-2 text-dark"
              to="/"
              style={({ isActive }) => getNavLinkStyle(isActive)}
            >
              Home
            </NavLink>
            <NavLink
              as={NavLink}
              className="active-link p-2 text-dark"
              to="/about"
              style={({ isActive }) => getNavLinkStyle(isActive)}
            >
              About
            </NavLink>
            <NavLink
              as={NavLink}
              className="active-link p-2 text-dark"
              to="/reviews"
              style={({ isActive }) => getNavLinkStyle(isActive)}
            >
              Reviews
            </NavLink>
            <NavLink
              as={NavLink}
              className="active-link p-2 text-dark"
              to="/forum"
              style={({ isActive }) => getNavLinkStyle(isActive)}
            >
              Forum
            </NavLink>
          </nav>
          {userToken.token !== "" ? (
            <NavDropdown
              title={
                <div className="user-circle">
                  {userType.name.charAt(0).toUpperCase()}
                </div>
              }
              id="basic-nav-dropdown"
              alignRight
              className="mx-3 "
            >
              <NavDropdown.Item className="" style={{ width: "25rem" }}>
                <div className="d-flex  align-items-center justify-content-between ">
                  <div className="d-flex  align-items-center">
                    <div className="user-circle " style={{ width: 35 }}>
                    {userType?.avatarName?.charAt(0).toUpperCase()}
                  </div>
                  <div className="w-50 mx-3">
                    <div style={{ fontSize: 14 }}>{userType?.avatarName}</div>
                    <div style={{ fontSize: 14 }}>{userType.email}</div>
                  </div>
                  </div>
                  <FaRegCircleDot color="blue" />
                </div>
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item as={NavLink} to="/my-reviews">
                My Reviews
              </NavDropdown.Item>
              <NavDropdown.Item as={NavLink} to="/myAllForums">
                My Forum Posts
              </NavDropdown.Item>
              <NavDropdown.Item as={NavLink} to="/settings">
                Settings
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <Button
               onClick={handleLogout}
                className="btn btn-outline mx-2 bg-transparent  "
                style={{
                fontSize: 14,
                width:"95%",
                color:"black",
                fontWeight: "600",
                letterSpacing: 0.38,
                borderWidth: 1,
                borderColor: "black",
                alignSelf:"center"
              }}
              >
                Logout
              </Button>
            </NavDropdown>
          ) : (
            <Button
              as={Link}
              className="btn btn-outline mx-2 bg-transparent "
              style={{
                fontSize: 14,
                fontWeight: "600",
                letterSpacing: 0.38,
                borderWidth: 1,
                borderColor: "black",
              }}
              to="/login"
            >
              <PiUserCircleFill color="black" size={22} />{" "}
              <span style={{ color: "black" }}>Sign in</span>
            </Button>
          )}
        </div>
      </div>
      <LogoutConfirmationModal
        show={showModal}
        handleClose={handleCloseModal}
        handleLogout={handleConfirmLogout}
      />
    </>
  );
}
