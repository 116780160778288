import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// import { useSelector } from "react-redux";
import { Container, Row, Col, ListGroup } from "react-bootstrap";
// import { token } from "../redux/tokenSlice";
import { GetRequest } from "../utils/apiCall";
import UserDetailsModal from "../componenets/userDetails";
import { Spinner } from 'react-bootstrap';


function SearchResults() {
  const { query } = useParams();
  const [allUsers, setAllUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showModal, setShowModal] = useState(false);
  // const userToken = useSelector(token);
  const [isLoading, setIsLoading] = useState(true); 
  const navigate = useNavigate();

  // Fetch all users on component mount
  useEffect(() => {
  setIsLoading(true)
    GetRequest(`/api/home/searchRateeUser/${query}`)
      .then((response) => {
            console.log("all results  => ",response.data.data);
        if (response.status) {
        const sortedUsers = response.data.data.sort((a, b) => new Date(b.date_created) - new Date(a.date_created));
          setAllUsers(sortedUsers);
        } else {
          console.error('Error:', response.error);
        }
      })
      .catch((error) => {
        console.error('Error fetching users:', error);
      }).finally(() => {
        setIsLoading(false); // Set loading to false when API call completes (success or error)
      });
  }, [query]);

// Handle user click
  const handleUserClick = (user) => {
    navigate(`/user/${user._id}`, { state: { user } });
  };

 // Close modal
  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedUser(null);
  };

  return (
    <>
     {isLoading ?
       
        <div className="text-center mt-4 d-flex align-items-center justify-content-center" style={{height:500}}>
          <Spinner animation="border" role="status" variant="success">
          </Spinner>
        </div>
      :
    <Container>
      
      <Row className="mt-4">
        <Col>
          <h2>Search Results</h2>
          <ListGroup style={allUsers.length < 10?{ height: 500 }:{}}>
            {allUsers.length > 0 ? (
              allUsers.map((user) => (
                <ListGroup.Item key={user._id} onClick={() => handleUserClick(user)}>
                  {user.name}  <span style={{fontSize:14}}>({user.institute?user.institute:"Institute name"} - {user.averageRating ?user.averageRating:5 })</span>
                </ListGroup.Item>
              ))
            ) : (
              <>

                <ListGroup.Item>No matched user available</ListGroup.Item>

                {/* <div style={{height:370}}></div> */}
              </>
            )}
          </ListGroup>
        </Col>
      </Row>
     {selectedUser && (
        <UserDetailsModal
          show={showModal}
          handleClose={handleCloseModal}
          user={selectedUser}
          getAllReviews={()=>{}}
        />
      )}
    </Container>
    }
    </>
  );
}

export default SearchResults;
