import React from 'react';
import { Navbar, Container } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { userDetail } from '../../redux/userDetailSlice';

function AdminHeader() {
  const user = useSelector(userDetail);


const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

  return (
    <Navbar style={{ backgroundColor: 'transparent', borderBottom: '1px solid #ccc' }} variant="light" className="m-0 w-100">
      <Container>
        <Navbar.Brand href="/" style={{ color: 'black' }}>Admin Dashboard</Navbar.Brand>
        <Navbar.Collapse className="justify-content-end">
          <Navbar.Text style={{ color: 'black' }}>
            Signed in as: <a href="#login" style={{ color: 'black' }}>{ capitalizeFirstLetter(user.name)}</a>
          </Navbar.Text>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default AdminHeader;
