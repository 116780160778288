import React, { useState } from "react";
import { Nav } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { token, updateToken } from "../../redux/tokenSlice";
import LogoutConfirmationModal from "../logoutModal";
import { userRole } from "../../redux/userDetailSlice";

function Sidebar() {

    const dispatch = useDispatch();
  const userToken = useSelector(token);
  const navigate = useNavigate();

  const location = useLocation();
  const [showModal, setShowModal] = useState(false); // State for showing/hiding modal

  const handleLogout = () => {
    setShowModal(true); // Show confirmation modal
  };

  const handleCloseModal = () => {
    setShowModal(false); // Hide confirmation modal
  };

  const handleConfirmLogout = () => {
    // Clear the token from the Redux store
    dispatch(updateToken(""));
    dispatch(userRole(""));
    // Navigate to the login page
    navigate("/login");
    // Close the modal
    setShowModal(false);
  };
  const navLinkStyle = {
        fontFamily: "Montserrat",
        color: '#333',
        textDecoration: 'none',
        fontSize:33,
        fontWeight:"bold"
    };

  return (
    <div className="d-flex flex-column vh-100 pt-2 w-100" style={{ width: "250px", borderRight: "1px solid #ccc" }}>
      <h2 className="text-center" style={navLinkStyle} >Rate My Admin</h2>
      <Nav className="flex-column">
            <Nav.Link as={NavLink} to="/allUsers" className="active-link fs-5 text-decoration-none text-dark" style={{ backgroundColor: location.pathname === "/allUsers" ? "white" : "", borderTop:"1px solid #ccc" }}>
              All Users
            </Nav.Link>
          
          {/* <Nav.Link as={NavLink} to="/" className="active-link fs-5 text-decoration-none text-dark" style={{ backgroundColor: location.pathname === "/" ? "white" : "", }} >
            Home
          </Nav.Link> */}
          <Nav.Link as={NavLink} to="/forum" className="active-link fs-5 text-decoration-none text-dark" style={{ backgroundColor: location.pathname === "/forum" ? "white" : "", borderTop:"1px solid #ccc"  }}>
            All Forum
          </Nav.Link>
          <Nav.Link as={NavLink} to="/reviews" className="active-link fs-5 text-decoration-none text-dark" style={{ backgroundColor: location.pathname === "/reviews" ? "white" : "", borderTop:"1px solid #ccc"  }}>
            All Reviews
          </Nav.Link>
          <Nav.Link as={NavLink} to="/about" className="active-link fs-5 text-decoration-none text-dark" style={{ backgroundColor: location.pathname === "/about" ? "white" : "", borderTop:"1px solid #ccc"  }}>
            About
          </Nav.Link>
          <Nav.Link as={NavLink} to="/blockedKeywords" className="active-link fs-5 text-decoration-none text-dark" style={{ backgroundColor: location.pathname === "/blockedKeywords" ? "white" : "",  borderTop:"1px solid #ccc" }}>
            Blocked Keywords
          </Nav.Link>
          <Nav.Link as={NavLink} to="/adminActivityLog" className="active-link fs-5 text-decoration-none text-dark" style={{ backgroundColor: location.pathname === "/adminActivityLog" ? "white" : "", borderTop:"1px solid #ccc"  }}>
            Admin Activity Log
          </Nav.Link>
          <Nav.Link as={NavLink} to="/categories" className="active-link fs-5 text-decoration-none text-dark" style={{ backgroundColor: location.pathname === "/categories" ? "white" : "", borderTop:"1px solid #ccc"  }}>
            Categories
          </Nav.Link>
          {userToken.token !== "" ? (
              <>
                <Nav.Link onClick={handleLogout} className="active-link fs-5 text-decoration-none text-dark" style={{ borderTop:"1px solid #ccc" }} >
                  Logout
                </Nav.Link>
              </>
            ) : (
              <Nav.Link as={NavLink} to="/login" className=" fs-5 text-decoration-none text-dark" style={{ backgroundColor: location.pathname === "/login" ? "white" : "" }} >
                Login
              </Nav.Link>
            )}
      </Nav>
      <LogoutConfirmationModal
        show={showModal}
        handleClose={handleCloseModal}
        handleLogout={handleConfirmLogout}
      />
    </div>
  );
}

export default Sidebar;
