/** @format */

import { Routes, Route } from "react-router-dom";
import PATH from "../utils/path";
import Dashboard from "../Pages/Dashboard.jsx";
import About from "../Pages/About.jsx";
import Reviews from "../Pages/Reviews.jsx";
import Forum from "../Pages/Forum.jsx";
import Login from "../Pages/LogIn.jsx";
import CreateForum from "../Pages/CreateForum.jsx";
import ForumDetailPage from "../Pages/ForumDetail.jsx";
import AllUsers from "../Pages/AllUsers.jsx";
import AllForums from "../Pages/AllForums.jsx";
import CreateUser from "../Pages/CreateNewUser.jsx";
import UserDetail from "../Pages/userDetail.jsx";
import MyForum from "../Pages/MyForums.jsx";
import MyLayout from "../componenets/layout/myLayout.js";
import SearchResults from "../Pages/SearchResults.jsx";
import BlockKeywords from "../Pages/BlockedKeyWords.jsx";
import SettingsPage from "../Pages/Settings.jsx";
import MyReviews from "../Pages/MyReviews.jsx";
import UserReviews from "../Pages/UserReviews.jsx";
import AdminActivityPage from "../Pages/AdminActivity.jsx";
import CategoriesPage from "../Pages/ForumCategories.jsx";
import VerifyEmail from "../Pages/verifyEmail.jsx";
import TermsOfUse from "../Pages/TermsOfUse.jsx";
import PrivacyPolicy from "../Pages/PrivacyPolicy.jsx";

function RouterConfig() {

  return (
    <Routes>
      {/* AUTH ROUTES START */}
     <Route element={<MyLayout />}>
      <Route
        path={PATH.Home}
        element={<Dashboard />}
      />
      <Route
        path={PATH.About}
        element={<About />}
      />
      <Route
        path={PATH.Reviews}
        element={<Reviews />}
      />
      <Route
        path={PATH.MyReviews}
        element={<MyReviews />}
      />
      <Route
        path={PATH.UserReviews}
        element={<UserReviews />}
      />
      <Route path="/detail/:id" element={<ForumDetailPage />} />
      <Route
        path={PATH.Forum}
        element={<Forum />}
      />
      <Route
        path={PATH.CreateForum}
        element={<CreateForum />}
      />
        <Route path="/user/:id" element={<UserDetail />} />
      <Route
        path={PATH.CreateUser}
        element={<CreateUser />}
      />
      <Route
        path={PATH.AllUsers}
        element={<AllUsers />}
      />
      <Route
        path={PATH.MyAllForums}
        element={<MyForum />}
      />
      <Route
        path={PATH.AdminActivityLog}
        element={<AdminActivityPage />}
      />
      <Route
        path={PATH.BlockedKeywords}
        element={<BlockKeywords />}
      />
      <Route
        path={PATH.LogIn}
        element={<Login />}
      />
      <Route
        path={PATH.Categories}
        element={<CategoriesPage />}
      />
      <Route
        path={PATH.VerifyEmail}
        element={<VerifyEmail />}
      />
      <Route
        path={PATH.Settings}
        element={<SettingsPage />}
      />
      <Route
        path={PATH.TermsOfUse}
        element={<TermsOfUse />}
      />
      <Route
        path={PATH.PrivacyPolicy}
        element={<PrivacyPolicy />}
      />
      <Route path="/search-results/:query" element={<SearchResults />} />
     </Route>
    </Routes>
  );
}

export default RouterConfig;
