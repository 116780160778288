import React, { useEffect, useState } from "react";
import { GetRequest } from "../utils/apiCall";
import { Spinner } from "react-bootstrap";

const AdminActivityPage = () => {
  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch admin activities from the API
    GetRequest("/api/home/getAdminActivities")
      .then((response) => {
        if (response.data.status) {
          // Sort activities by date, newest first
          const sortedActivities = response.data.data.sort(
            (a, b) => new Date(b.dateTime) - new Date(a.dateTime)
          );
          setActivities(sortedActivities);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error(
          "There was an error fetching the admin activities!",
          error
        );
        setLoading(false);
      });
  }, []);

  const getActivityTitle = (activity) => {
    if (activity.commentId && activity.replyId && activity.activityType ==="delete") return "Reply Deleted";
    if (activity.commentId && activity.replyId) return "Reply Edited";
    if (activity.commentId && activity.activityType ==="delete") return "Comment Deleted";
    if (activity.commentId) return "Comment Edited";
    if (activity.forumId && activity.activityType ==="delete") return "Forum Deleted";
    if (activity.forumId) return "Forum Edited";
    if (activity.reviewId && activity.activityType ==="delete") return "Review Deleted";
    if (activity.reviewId) return "Review Edited";
    return "Activity";
  };

  return (
    <div className="container my-5">
      <h1>Admin Activity Logs</h1>
      {loading ? (
        <div
          className="text-center mt-4 d-flex align-items-center justify-content-center"
          style={{ height: 500 }}
        >
          <Spinner animation="border" role="status" variant="success"></Spinner>
        </div>
      ) : (
        <ul className="list-group">
          {activities.map((activity) => (
            <li key={activity._id} className="list-group-item p-4">
              <h5>{getActivityTitle(activity)}</h5>
              <p className="p-0 m-0">
                <strong>Admin ID:</strong> {activity.adminId}
              </p>
              <p  className="p-0 m-0">
                <strong>Original Content:</strong> {activity.previousContent || "null"}
              </p>
              <p className="p-0 m-0">
                <strong>Updated Content:</strong> {activity.updatedContent}
              </p>
              <p className="p-0 m-0">
                <small className="text-muted">
                  Date: {new Date(activity.dateTime).toLocaleString()}
                </small>
              </p>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default AdminActivityPage;
