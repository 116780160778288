import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { GetRequest } from '../utils/apiCall';

const VerifyEmail = () => {
    const location = useLocation();
    const [message, setMessage] = useState('');

    useEffect(() => {
        const verifyEmail = async () => {
            const queryParams = new URLSearchParams(location.search);
            const token = queryParams.get('token');

            if (token) {
                try {
                    const response = await GetRequest(`/api/verify-email?token=${token}`);
                    setMessage(response.data.message);
                } catch (error) {
                    setMessage(error.response.data.message || 'Verification failed.');
                }
            } else {
                setMessage('Invalid verification link.');
            }
        };

        verifyEmail();
    }, [location.search]);

    return (
        <div>
            <h2>Email Verification</h2>
            <p>{message}</p>
        </div>
    );
};

export default VerifyEmail;
