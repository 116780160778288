import React, { useState } from "react";
import { Form, Button, Container, Row, Col, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { user, userDetail } from "../redux/userDetailSlice";
import { UpdateRatee, UpdateUser } from "../utils/apiCall";
import { Toaster, toast } from "react-hot-toast";

const SettingsPage = () => {
  const userType = useSelector(userDetail);

  const [name, setName] = useState(userType.name);
  const [avatarName, setAvatarName] = useState(userType.avatarName);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const handleNameChange = (e) => setName(e.target.value);
  const handleAvatarNameChange = (e) => setAvatarName(e.target.value);
  const handleCurrentPasswordChange = (e) => setCurrentPassword(e.target.value);
  const handleNewPasswordChange = (e) => setNewPassword(e.target.value);
  const handleConfirmNewPasswordChange = (e) => setConfirmNewPassword(e.target.value);

  const updatePassword = async () => {
    setIsLoading(true);

    if (newPassword !== confirmNewPassword) {
      setIsLoading(false);
      toast.error("New password and confirm new password do not match");
      return;
    }

    const data = {
      name: name,
      avatarName: avatarName,
      email: userType.email,
      previousPassword: currentPassword,
      newPassword: newPassword,
    };

    try {
      const response = await UpdateUser(
        data,
        `/api/auth/updateUser/${userType._id}`
      );
      if (response.status) {
        setIsLoading(false);
        toast.success("Profile updated successfully");
        dispatch(user(response.data.data));
      } else {
        setIsLoading(false);
        toast.error("Error updating profile");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error updating profile:", error);
      toast.error("Error updating profile");
    }
  };

  const updateName = async () => {
    setIsLoading(true);

    const data = {
      name: name,
      avatarName: avatarName,
      email: userType.email,
    };

    try {
      const response = await UpdateRatee(
        data,
        `/api/auth/updateUser/${userType._id}`
      );
      if (response.status) {
        setIsLoading(false);
        toast.success("Profile updated successfully");

        dispatch(user(response.data.data));
      } else {
        setIsLoading(false);
        toast.error("Error updating profile");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error updating profile:", error);
      toast.error("Error updating profile");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newPassword === "") {
      updateName();
    } else {
      updatePassword();
    }
  };

  return (
    <Container style={{ marginBottom: "5%" }}>
      <Row className="justify-content-center">
        <Col md={6}>
          <h2 className="mt-5">Settings</h2>
          <Form>
            <Form.Group controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                value={name}
                onChange={handleNameChange}
                placeholder="Enter your name"
                required
              />
            </Form.Group>
            <Form.Group controlId="name" className="mt-3">
              <Form.Label>Avatar Name</Form.Label>
              <Form.Control
                type="text"
                value={avatarName}
                onChange={handleAvatarNameChange}
                placeholder="Enter your Avatar Name"
                required
              />
            </Form.Group>

            <Form.Group controlId="currentPassword" className="mt-3">
              <Form.Label>Current Password</Form.Label>
              <Form.Control
                type="password"
                value={currentPassword}
                onChange={handleCurrentPasswordChange}
                placeholder="Enter current password"
                required
              />
            </Form.Group>

            <Form.Group controlId="newPassword" className="mt-3">
              <Form.Label>New Password</Form.Label>
              <Form.Control
                type="password"
                value={newPassword}
                onChange={handleNewPasswordChange}
                placeholder="Enter new password"
                required
              />
            </Form.Group>

            <Form.Group controlId="confirmNewPassword" className="mt-3">
              <Form.Label>Confirm New Password</Form.Label>
              <Form.Control
                type="password"
                value={confirmNewPassword}
                onChange={handleConfirmNewPasswordChange}
                placeholder="Confirm new password"
                required
              />
            </Form.Group>
            {isLoading ? (
              <div className="text-center mt-4 d-flex align-items-center justify-content-center">
                <Spinner
                  animation="border"
                  role="status"
                  variant="success"
                ></Spinner>
              </div>
            ) : (
              <Button variant="primary" onClick={handleSubmit} className="mt-4">
                Update Profile
              </Button>
            )}
          </Form>
        </Col>
      </Row>
      <Toaster />
    </Container>
  );
};

export default SettingsPage;
