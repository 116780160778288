import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { token } from "../redux/tokenSlice";

function AllForums() {
   const dummyData = [
    { id: 1, name: "Topic 1" ,review:"Donec ullamcorper nulla non metus auctor fringilla. Nulla vitae elit libero, a pharetra augue.Donec ullamcorper nulla non metus auctor fringilla. Nulla vitae elit libero, a pharetra augue.Donec ullamcorper nulla non metus auctor fringilla. Nulla vitae elit libero, a pharetra augue.Donec ullamcorper nulla non metus auctor fringilla. Nulla vitae elit libero, a pharetra augue.Donec ullamcorper nulla non metus auctor fringilla. Nulla vitae elit libero, a pharetra augue."},
    { id: 2, name: "Topic 2",review:"Donec ullamcorper nulla non metus auctor fringilla. Nulla vitae elit libero, a pharetra augue." },
    { id: 3, name: "Topic 3",review:"Donec ullamcorper nulla non metus auctor fringilla. Nulla vitae elit libero, a pharetra augue." },
    { id: 4, name: "Topic 4",review:"Donec ullamcorper nulla non metus auctor fringilla. Nulla vitae elit libero, a pharetra augue." },
    { id: 5, name: "Topic 5" ,review:"Donec ullamcorper nulla non metus auctor fringilla. Nulla vitae elit libero, a pharetra augue."},
    { id: 6, name: "Topic 6",review:"Donec ullamcorper nulla non metus auctor fringilla. Nulla vitae elit libero, a pharetra augue." },
    { id: 7, name: "Topic 7" ,review:"Donec ullamcorper nulla non metus auctor fringilla. Nulla vitae elit libero, a pharetra augue."},
    { id: 8, name: "Topic 8",review:"Donec ullamcorper nulla non metus auctor fringilla. Nulla vitae elit libero, a pharetra augue." },
    { id: 9, name: "Topic 9",review:"Donec ullamcorper nulla non metus auctor fringilla. Nulla vitae elit libero, a pharetra augue." },
    { id: 10, name: "Topic 10" ,review:"Donec ullamcorper nulla non metus auctor fringilla. Nulla vitae elit libero, a pharetra augue."},
    { id: 11, name: "Topic 11" ,review:"Donec ullamcorper nulla non metus auctor fringilla. Nulla vitae elit libero, a pharetra augue."},
    { id: 12, name: "Topic 12",review:"Donec ec ullamcorper nulla non metus auctor fringilla. Nulla vitae elit libero, a pharetra augue." },
    { id: 13, name: "Topic 13" ,review:"Donec ullamcorper nulla non metus auctor fringilla. Nulla vitae elit libero, a pharetra augue."},
    { id: 14, name: "Topic 14" ,review:"Donec ullamcorper nulla non metus auctor fringilla. Nulla vitae elit libero, a pharetra augue."},
    { id: 15, name: "Topic 15",review:"Donec ullamcorper nulla non metus auctor fringilla. Nulla vitae elit libero, a pharetra augue."},
    { id: 16, name: "Topic 16" ,review:"Donec ullamcorper nulla non metus auctor fringilla. Nulla vitae elit libero, a pharetra augue."},
    { id: 17, name: "Topic 17",review:"Donec ullamcorper nulla non metus auctor fringilla. Nulla vitae elit libero, a pharetra augue." },
    { id: 18, name: "Topic 18",review:"Donec ullamcorper nulla non metus auctor fringilla. Nulla vitae elit libero, a pharetra augue." },
    { id: 19, name: "Topic 19",review:"Donec ullamcorper nulla non metus auctor fringilla. Nulla vitae elit libero, a pharetra augue." },
    { id: 20, name: "Iqra",review:"Donec ullamcorper nulla non metus auctor fringilla. Nulla vitae elit libero, a pharetra augue." },
    { id: 21, name: "Mohsin",review:"Donec ullamcorper nulla non metus auctor fringilla. Nulla vitae elit libero, a pharetra augue." },
    { id: 22, name: "Alia" ,review:"Donec ullamcorper nulla non metus auctor fringilla. Nulla vitae elit libero, a pharetra augue."},
    { id: 23, name: "Mahira",review:"Donec ullamcorper nulla non metus auctor fringilla. Nulla vitae elit libero, a pharetra augue." },
    { id: 24, name: "Adem" ,review:"Donec ullamcorper nulla non metus auctor fringilla. Nulla vitae elit libero, a pharetra augue."},
    { id: 25, name: "Smith" ,review:"Donec ullamcorper nulla non metus auctor fringilla. Nulla vitae elit libero, a pharetra augue."},
    { id: 26, name: "Gohar" ,review:"Donec ullamcorper nulla non metus auctor fringilla. Nulla vitae elit libero, a pharetra augue."},
    { id: 27, name: "Inzmam" ,review:"Donec ullamcorper nulla non metus auctor fringilla. Nulla vitae elit libero, a pharetra augue."},
    { id: 28, name: "Usman" ,review:"Donec ullamcorper nulla non metus auctor fringilla. Nulla vitae elit libero, a pharetra augue."},
    { id: 29, name: "Inzmam" ,review:"Donec ullamcorper nulla non metus auctor fringilla. Nulla vitae elit libero, a pharetra augue."},
    { id: 30, name: "Usman",review:"Donec ullamcorper nulla non metus auctor fringilla. Nulla vitae elit libero, a pharetra augue."},
    { id: 31, name: "Haris" ,review:"Donec ullamcorper nulla non metus auctor fringilla. Nulla vitae elit libero, a pharetra augue."},
    { id: 32, name: "Amin" ,review:"Donec ullamcorper nulla non metus auctor fringilla. Nulla vitae elit libero, a pharetra augue." },
  ];

  const [searchQuery, setSearchQuery] = useState("");
  const [data, setData] = useState([]); // Data state (initially contains all data)
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(15); // Number of items per page
  // Function to handle search input change
  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    // Filter data based on search query
    const filteredData = dummyData.filter((item) =>
      item.name.toLowerCase().includes(query.toLowerCase())
    );
    setData(filteredData);
    setCurrentPage(1); // Reset to first page after search
  };

  const navigate = useNavigate();

  // Function to handle pagination change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    // Typically, you would fetch data from API based on pageNumber
  };

  // Function to paginate data based on current page
  const paginate = (data) => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return data.slice(startIndex, endIndex);
  };

 const handleItemClick = (item) => {
    navigate(`/detail/${item.id}`, { state: { item } });
  };

  // Example data rendering logic (adjust as per your data structure)
  const renderData = () => {
    const paginatedData = paginate(data);
    return (
      <ul className="list-group">
        {paginatedData.map((item) => (
         <li
            key={item.id}
            className="list-group-item"
            onClick={() => handleItemClick(item)}
            style={{ cursor: "pointer" }}
          >
            <div>{item.name}</div>
            <div
              style={{
                fontSize: 12,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {item.review}
            </div>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div className="container">
      <div className="row justify-content-left mt-4 ">
        <div className="col-md-6">
          <input
            type="text"
            className="form-control"
            placeholder="Search Forum"
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </div>
      </div>
      <section className="card-sec my-3">
        <div className="row">
          <div className="col">
            {renderData()}
            {/* Pagination UI */}
            <nav>
              <ul className="pagination justify-content-center mt-3">
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                  <button className="page-link" onClick={() => handlePageChange(currentPage - 1)} style={{width:80}}>
                    Previous
                  </button>
                </li>
                <li className="page-item">
                  <span className="page-link">{currentPage}</span>
                </li>
                <li className={`page-item ${currentPage === Math.ceil(data.length / itemsPerPage) ? 'disabled' : ''}`}>
                  <button className="page-link" onClick={() => handlePageChange(currentPage + 1)} style={{width:80}}>
                    Next
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </section>
    </div>
  );
}

export default AllForums;
