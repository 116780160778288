import React, { useState } from "react";
import { Button, Card, Form, Modal, Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { PostRequest } from "../utils/apiCall";
import { Toaster, toast } from "react-hot-toast";
import { updateToken } from "../redux/tokenSlice";
import { Link, useNavigate } from "react-router-dom";
import { user, userRole } from "../redux/userDetailSlice";
import { apiEndpoints } from "../utils/appUris";

function Login() {
  document.body.style.backgroundColor = "#f5f5f5";

  const [show, setShow] = useState(false);
  const [otpShow, setOtpShow] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [verficationMessage, setVerficationMessage] = useState("");
  const [otp, setOtp] = useState(""); // State for OTP
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    verifyPassword: "",
    avatarName: "",
  });

  const navigate = useNavigate();

  const handleClose = () => {
    setShow(false);
    setOtpShow(false); // Close OTP modal
  };
  const handleShow = () => setShow(true);
  const handleOtpShow = () => setOtpShow(true);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const handleSignup = () => {
  setLoading(true);
  const data = {
    email: formData.email,
    name: `${formData.firstName} ${formData.lastName}`,
    avatarName: formData.avatarName,
    password: formData.password,
  };

  PostRequest(data, apiEndpoints.signUp)
    .then((res) => {

      if (res.status) {
        setLoading(false);
        setVerficationMessage(res.data.message || "");
        setShow(false);
        setEmail(formData.email);
        setPassword(formData.password);
        handleOtpShow(); // Show OTP verification modal
      } else {
        // If the error comes from the API response
        if (res.error && res.error.response.status === 400) {
          toast.error(res.error.response.data.message || "Try Again Later", { duration: 5000 }); // Adjust duration if needed
        } else {
          toast.error("An unexpected error occurred. Please try again later.", { duration: 5000 });
        }
        setLoading(false); // Ensure loading stops even on error
      }
    })
    .catch((error) => {
      // If there's a network or other issue
      console.error("Error during signup:", error);
      toast.error("Error signing up. Please try again later.", { duration: 5000 });
      setLoading(false); // Stop the loading spinner
    });
};

  const signUp = () => {
    formData.firstName === ""
      ? toast.error("Please add First Name")
      : formData.avatarName === ""
      ? toast.error("Please add Avatar Name")
      : formData.email === ""
      ? toast.error("Please add Email")
      : !validateEmail(formData.email)
      ? toast.error("Please enter a valid email address.")
      : formData.password === ""
      ? toast.error("Please add Password")
      : formData.verifyPassword === ""
      ? toast.error("Please add Password For confirmation")
      : formData.password !== formData.verifyPassword
      ? toast.error("Password is not Matched")
      : handleSignup();
  };

  const handleSignIn = () => {
    setLoading(true);
    const data = {
      email: email,
      password: password,
    };
    PostRequest(data, apiEndpoints.login)
      .then((res) => {
        
        if (res.status) {
          setLoading(false);
          if (res.data.data.user.isBlocked) {
            toast.error("Your account is blocked");
          } else {
            toast.success("Login successfully");
              dispatch(updateToken(res.data.data.token));
              dispatch(userRole(res.data.data.user.role));
              dispatch(user(res.data.data.user));
              navigate(res.data.data.user.role === "ADMIN" ? "/forum" : "/");
          }
        } else {

          if (res.error && res.error.response.data.message.includes("Account not verified.")) {
            setVerficationMessage(res.error.response.data.message || "")
            setOtpShow(true); // Show OTP modal
            setLoading(false);
          }else {

          setLoading(false);
          toast.error(res.error.response.data.message || "Try again later");
          }
        }
      });
  };

  const signIn = (e) => {
    e.preventDefault();
    email === ""
      ? toast.error("Please add Email")
      : !validateEmail(email)
      ? toast.error("Please enter a valid email address.")
      : password === ""
      ? toast.error("Please add Password")
      : handleSignIn();
  };

  const handleOtpVerify = (e) => {
    e.preventDefault();
    const data = {
      email: formData.email === "" ? email : formData.email,
      otp: otp,
    };

    PostRequest(data, apiEndpoints.verifyOtp)
      .then((res) => {
        if (res.status) {
          toast.success("OTP verified successfully");
          setTimeout(() => {
          setOtpShow(false);
          handleSignIn();
          }, 1000);
        } else {
          toast.error("Invalid OTP. Please try again.");
        }
      })
      .catch((error) => {
        console.error("Error verifying OTP:", error);
        toast.error("An error occurred. Please try again.");
      });
  };

  return (
    <div className="d-flex flex-column align-items-center justify-content-center vh-100 w-100 bg-light">
      <Card className="p-5 shadow">
        <h2 className="text-center">Login</h2>
        <Form onSubmit={signIn}>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              placeholder="Enter email"
            />
          </Form.Group>

          <Form.Group controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
            />
          </Form.Group>

          <Button variant="primary" type="submit" className="w-100 my-2">
            {loading?<Spinner animation="border" role="status" variant="success" /> : "Login"}
          </Button>
          <div className="text-center my-2">
            <p>
              Don't have an account?{" "}
              <span
                style={{
                  backgroundColor: "transparent",
                  textDecoration: "underline",
                  cursor: "pointer",
                  fontWeight: "bold",
                }}
                onClick={handleShow}
              >
                Create your account
              </span>
            </p>
          </div>
        </Form>
      </Card>
      <Toaster />

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Sign Up</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="p-3" >
                <div className="d-flex align-items-center mb-3">
                  <Form.Control
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    className="border-0 border-bottom rounded-0"
                    type="text"
                    placeholder="First Name..."
                  />
                </div>
                <div className="d-flex align-items-center">
                  <Form.Control
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    className="border-0 border-bottom rounded-0"
                    type="text"
                    placeholder="Last Name..."
                  />
                </div>
            <div className="mb-3 d-flex align-items-center">
            </div>
            <Form.Group className="mb-3">
              <div className="d-flex align-items-center">
                {/* <MdEmail className="me-2" size={30} fill="#495057" /> */}
                <Form.Control
                  name="avatarName"
                  value={formData.avatarName}
                  onChange={handleChange}
                  className="border-0 border-bottom rounded-0"
                  type="text"
                  placeholder="Avatar Name..."
                />
              </div>
            </Form.Group>
            <Form.Group className="mb-3">
              <div className="d-flex align-items-center">
                <Form.Control
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="border-0 border-bottom rounded-0"
                  type="email"
                  placeholder="Email..."
                />
              </div>
            </Form.Group>

                <Form.Group className="mb-3">
                  <div className="d-flex align-items-center ">

                    <Form.Control
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                      className="border-0 border-bottom rounded-0"
                      type="password"
                      placeholder="Password..."
                    />
                  </div>
                </Form.Group>
                <Form.Group className="mb-3">
                  <div className="d-flex align-items-center ">
                    <Form.Control
                      name="verifyPassword"
                      value={formData.verifyPassword}
                      onChange={handleChange}
                      className="border-0 border-bottom rounded-0"
                      type="password"
                      placeholder="Confirm Password..."
                    />
                  </div>
                </Form.Group>
            <Form.Group className="mb-3 d-flex align-items-center mt-5">
              <Form.Check className="mx-2" type="checkbox" />
              <label>
                I agree with the <Link to="/terms-of-use">terms of use </Link>
                and the <Link to="/privacy-policy">privacy policy</Link>.
              </label>
            </Form.Group>
            <Button
              variant="primary"
              // type="submit"
              className="d-flex align-items-center d-block m-auto py-3 px-5 mt-4"
              onClick={()=>signUp()}
            >
              {loading?<Spinner animation="border" role="status" variant="success" /> : "Register"}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal show={otpShow} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Verify OTP</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{verficationMessage}</p>
          <Form onSubmit={handleOtpVerify}>
            <Form.Group controlId="formBasicOtp">
              <Form.Label>Enter OTP</Form.Label>
              <Form.Control
                type="text"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                placeholder="Enter OTP sent to your email"
              />
            </Form.Group>
            <Button variant="primary" type="submit" className="w-100 my-2">
              Verify OTP
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Login;
