import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  DeleteComment,
  GetRequest,
  PostRequest,
  updateForumStatus,
} from "../utils/apiCall";
import { userDetail } from "../redux/userDetailSlice";
import { token } from "../redux/tokenSlice";
import { FaChevronDown } from "react-icons/fa";

function Forum() {
  const [searchQuery, setSearchQuery] = useState("");
  const [allForums, setAllForums] = useState([]);
  const [filteredForums, setFilteredForums] = useState([]);
  const navigate = useNavigate();
  const userType = useSelector(userDetail);
  const userToken = useSelector(token);
  const [isLoading, setIsLoading] = useState(false);
  const [adminActivity, setAdminActivity] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");

  const MAX_LENGTH = 200;

  // Function to truncate text to a maximum length
  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    }
    return text;
  };

  const getAllForums = () => {
    setIsLoading(true);
    try {
      GetRequest("/api/home/allforums").then((res) => {
        if (res.status) {
          const sortedForums = res.data.data.sort(
            (a, b) => new Date(b.createDate) - new Date(a.createDate)
          );
          setAllForums(sortedForums);
          console.log("all forums ==>", sortedForums);

          setFilteredForums(sortedForums); // Initially, show all forums
        }
        setIsLoading(false);
      });
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const fetchAdminActivity = async () => {
    const resActivity = await GetRequest(`/api/home/getAdminActivities`);
    if (resActivity.status) {
      setAdminActivity(resActivity.data.data);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await GetRequest("/api/home/getCategories"); // Adjust endpoint if needed
      if (response.status) {
        setCategories(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  useEffect(() => {
    getAllForums();
    fetchAdminActivity();
    fetchCategories();
  }, []);

  const handleCategoryChange = (event) => {
    const category = event.target.value;
    setSelectedCategory(category);

    const filtered = allForums.filter((forum) => {
      const matchesCategory = category === "" || forum.category === category;
      const matchesSearch = forum.heading
        .toLowerCase()
        .includes(searchQuery.toLowerCase());
      return matchesCategory && matchesSearch;
    });

    setFilteredForums(filtered);
  };

  const handleEditClick = (item) => {
    navigate(`/create-forum`, { state: { item } });
  };

  const deleteForum = (item) => {
    DeleteComment(`/api/home/deleteForum/${item._id}`).then((res) => {
      const adminData = {
        activityType: "delete",
        forumId: item._id,
        previousContent: `Heading : ${item.heading}, Description : ${item.description}`,
        updatedContent: "Forum deleted successfully",
        adminId: userType._id,
      };
      if (userType.role === "ADMIN") {
        PostRequest(adminData, "/api/home/logActivity").then((res) => {
          console.log("adminActivity ==> ", res);
        });
      }
      getAllForums();
    });
  };

  // Handle search input change
  const handleSearchChange = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
    const filtered = allForums.filter((forum) =>
      forum.heading.toLowerCase().includes(query)
    );
    setFilteredForums(filtered);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    // Get date components
    const year = date.getFullYear();
    const month = date.toLocaleString("en-US", { month: "long" });
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0");

    // Determine AM/PM and convert hour to 12-hour format
    const ampm = hours >= 12 ? "pm" : "am";
    const hour12 = hours % 12 || 12;

    // Add suffix to day (st, nd, rd, th)
    const daySuffix = (day) => {
      if (day > 3 && day < 21) return "th"; // Covers 11th - 20th
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };

    // Format the final date string
    return `${month} ${day}${daySuffix(
      day
    )}, ${year}, ${hour12}:${minutes} ${ampm}`;
  };

  // Handle forum item click
  const handleItemClick = (item) => {
    navigate(`/detail/${item._id}`, { state: { item } });
  };

  // Handle activate/deactivate button click
  const handleStatusToggle = (forum) => {
    setIsLoading(true);
    const updatedStatus = forum.status === "active" ? "inactive" : "active";
    updateForumStatus(forum._id, updatedStatus)
      .then((res) => {
        if (res.status) {
          setAllForums((prevForums) =>
            prevForums.map((f) =>
              f._id === forum._id ? { ...f, status: updatedStatus } : f
            )
          );
          setFilteredForums((prevForums) =>
            prevForums.map((f) =>
              f._id === forum._id ? { ...f, status: updatedStatus } : f
            )
          );
        } else {
          console.error("Error updating forum status:", res.error);
        }
      })
      .catch((error) => {
        console.error("Error updating forum status:", error);
      })
      .finally(() => {
        setIsLoading(false); // Set loading to false when API call completes (success or error)
      });
  };

  // Render filtered forums
  // const renderForums = () => {
  //   // Filter out deactivated forums if user is not an ADMIN
  //   const filteredItems = filteredForums.filter(
  //     (item) =>
  //       ((userType.role === "ADMIN" || userType.role === "OPERATOR") &&
  //         userToken.token !== "") ||
  //       item.status === "active"
  //   );
  //   if (isLoading) {
  //     return (
  //       <div
  //         className="text-center mt-4 d-flex align-items-center justify-content-center"
  //         style={{ height: 500 }}
  //       >
  //         <Spinner animation="border" role="status" variant="success"></Spinner>
  //       </div>
  //     );
  //   }
  //   if (filteredItems.length === 0) {
  //     return (
  //       <div
  //         className="d-flex align-items-center justify-content-center"
  //         style={{ height: 380 }}
  //       >
  //         <p className="text-muted">No forum created yet.</p>
  //       </div>
  //     );
  //   }

  //   return (
  //     <ul
  //       className="list-group"
  //       style={filteredItems.length < 6 ? { height: 480 } : {}}
  //     >
  //       {filteredItems.map((item) => {
  //         const isEditedByAdmin = adminActivity.some(
  //           (activity) => activity.forumId === item._id
  //         );
  //         return (
  //           <li
  //             key={item._id}
  //             className="list-group-item d-flex justify-content-between align-items-center"
  //             onClick={() => handleItemClick(item)}
  //             style={{ cursor: "pointer" }}
  //           >
  //             <div>
  //               <div>
  //                 {item.heading}
  //                 <span
  //                   style={{
  //                     fontSize: "0.8em",
  //                     color: "grey",
  //                     marginLeft: "10px",
  //                   }}
  //                 >
  //                   (Created By {item?.userId?.avatarName})
  //                 </span>
  //                 <span
  //                   style={{
  //                     fontSize: "0.8em",
  //                     color: "grey",
  //                     marginLeft: "10px",
  //                   }}
  //                 >
  //                   {formatDate(item.createDate)}
  //                 </span>
  //               </div>
  //               <div
  //                 style={{
  //                   fontSize: 12,
  //                   whiteSpace: "nowrap",
  //                   overflow: "hidden",
  //                   textOverflow: "ellipsis",
  //                 }}
  //                 dangerouslySetInnerHTML={{
  //                   __html: truncateText(item.description, MAX_LENGTH),
  //                 }}
  //               />
  //               {isEditedByAdmin && (
  //                 <div className="text-muted p-0 m-0 ">
  //                   <small>Edited by admin</small>
  //                 </div>
  //               )}
  //             </div>
  //             <Row>
  //               {(userType.role === "ADMIN" || userType.role === "OPERATOR") &&
  //                 userToken.token !== "" && (
  //                   <Button
  //                     style={{ width: 100 }}
  //                     variant={item.status === "active" ? "danger" : "success"}
  //                     onClick={(e) => {
  //                       e.stopPropagation();
  //                       handleStatusToggle(item);
  //                     }}
  //                   >
  //                     {item.status === "active" ? "Deactivate" : "Activate"}
  //                   </Button>
  //                 )}
  //               {(userType.role === "ADMIN" ||
  //                 userType.role === "OPERATOR" ||
  //                 userType._id === item?.userId?._id) &&
  //                 userToken.token !== "" && (
  //                   <>
  //                     <Button
  //                       style={{ width: 100, marginLeft: 5, marginRight: 5 }}
  //                       variant="secondary"
  //                       size="sm"
  //                       onClick={(e) => {
  //                         e.stopPropagation(); // Prevent the item click handler from firing
  //                         handleEditClick(item);
  //                       }}
  //                     >
  //                       Edit
  //                     </Button>
  //                     <Button
  //                       style={{ width: 100 }}
  //                       variant="danger"
  //                       size="sm"
  //                       onClick={(e) => {
  //                         e.stopPropagation(); // Prevent the item click handler from firing
  //                         deleteForum(item);
  //                       }}
  //                     >
  //                       Delete
  //                     </Button>
  //                   </>
  //                 )}
  //             </Row>
  //           </li>
  //         );
  //       })}
  //     </ul>
  //   );
  // };

  const renderForums = () => {
  // Filter out deactivated forums if the user is not an ADMIN or OPERATOR
  const filteredItems = filteredForums.filter(
    (item) =>
      ((userType.role === "ADMIN" || userType.role === "OPERATOR") &&
        userToken.token !== "") ||
      item.status === "active"
  );

  // Group forums by category, assign to "General" if no category specified
  const forumsByCategory = filteredItems.reduce((acc, item) => {
    const category = item.category || "General"; // Default to "General" if no category
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(item);
    return acc;
  }, {});

  if (isLoading) {
    return (
      <div
        className="text-center mt-4 d-flex align-items-center justify-content-center"
        style={{ height: 500 }}
      >
        <Spinner animation="border" role="status" variant="success"></Spinner>
      </div>
    );
  }

  if (Object.keys(forumsByCategory).length === 0) {
    return (
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ height: 380 }}
      >
        <p className="text-muted">No forum created yet.</p>
      </div>
    );
  }

  return (
    <>
      {Object.keys(forumsByCategory).map((category) => (
        <div key={category} className="category-section my-2">
          <h4 className="category-title">{category}</h4>
          <ul className="list-group">
            {forumsByCategory[category].map((item) => {
              const isEditedByAdmin = adminActivity.some(
                (activity) => activity.forumId === item._id
              );
              return (
                <li
                  key={item._id}
                  className="list-group-item d-flex justify-content-between align-items-center"
                  onClick={() => handleItemClick(item)}
                  style={{ cursor: "pointer", width:"100%" }}
                >
                  <div style={{width:"70%"}}>
                    <div>
                      {item.heading}
                      <span
                        style={{
                          fontSize: "0.8em",
                          color: "grey",
                          marginLeft: "10px",
                        }}
                      >
                        (Created By {item?.userId?.avatarName})
                      </span>
                      <span
                        style={{
                          fontSize: "0.8em",
                          color: "grey",
                          marginLeft: "10px",
                        }}
                      >
                        {formatDate(item.createDate)}
                      </span>
                    </div>
                    <div
                      style={{
                        fontSize: 12,
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                      className="w-75"
                      dangerouslySetInnerHTML={{
                        __html: truncateText(item.description, MAX_LENGTH),
                      }}
                    />
                    {isEditedByAdmin && (
                      <div className="text-muted p-0 m-0 ">
                        <small>Edited by admin</small>
                      </div>
                    )}
                  </div>
                  <Row style={{width:"30%"}}>
                    {(userType.role === "ADMIN" || userType.role === "OPERATOR") &&
                      userToken.token !== "" && (
                        <Button
                          style={{  width: "30%",textAlign:"center" }}
                          className="my-1"
                          variant={item.status === "active" ? "danger" : "success"}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleStatusToggle(item);
                          }}
                        >
                          {item.status === "active" ? "Deactivate" : "Activate"}
                        </Button>
                      )}
                    {(userType.role === "ADMIN" ||
                      userType.role === "OPERATOR" ||
                      userType._id === item?.userId?._id) &&
                      userToken.token !== "" && (
                        <>
                          <Button
                            style={{  width: "30%", marginLeft: 5, marginRight: 5 }}
                          className="my-1"
                            variant="secondary"
                            size="sm"
                            onClick={(e) => {
                              e.stopPropagation(); // Prevent the item click handler from firing
                              handleEditClick(item);
                            }}
                          >
                            Edit
                          </Button>
                          <Button
                            style={{ width: "30%" }}
                          className="my-1"
                            variant="danger"
                            size="sm"
                            onClick={(e) => {
                              e.stopPropagation(); // Prevent the item click handler from firing
                              deleteForum(item);
                            }}
                          >
                            Delete
                          </Button>
                        </>
                      )}
                  </Row>
                </li>
              );
            })}
          </ul>
        </div>
      ))}
    </>
  );
};


  return (
    <div className="container">
      <Row className="justify-content-left mt-4">
        <Col md={6}>
          <Form.Control
            type="text"
            placeholder="Search for a Discussion topic..."
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </Col>
        {userType.role === "ADMIN" && userToken.token !== "" && (
          <Col md={2} className="dropdown-select">
            <Form.Control
              as="select"
              value={selectedCategory}
              onChange={handleCategoryChange}
              style={{ cursor: "pointer" }}
            >
              <option value="">All Categories</option>
              {categories.map((category) => (
                <option key={category._id} value={category.name}>
                  {category.name}
                </option>
              ))}
            </Form.Control>
            <FaChevronDown className="dropdown-icon" />
          </Col>
        )}
        <Col md={3}>
          <Button
            as={Link}
            to={userToken.token != "" ? "/create-forum" : "/login"}
            variant="primary"
          >
            Create Forum Topic
          </Button>
        </Col>
      </Row>
      <section className="card-sec my-3">
        <div className="row">
          <div className="col">{renderForums()}</div>
        </div>
      </section>
    </div>
  );
}

export default Forum;
