import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { token, updateToken } from "../redux/tokenSlice";
import { useNavigate } from "react-router-dom";
import LogoutConfirmationModal from "./logoutModal";
import { userRole } from "../redux/userDetailSlice";

const Footer = () => {
  const userToken = useSelector(token);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false); // State for showing/hiding modal

  const handleLogout = () => {
    setShowModal(true); // Show confirmation modal
  };

  const handleCloseModal = () => {
    setShowModal(false); // Hide confirmation modal
  };

  const handleConfirmLogout = () => {
    // Clear the token from the Redux store
    dispatch(updateToken(""));
    dispatch(userRole(""));
    // Navigate to the login page
    navigate("/login");
    // Close the modal
    setShowModal(false);
  };

  return (
    <div className="container">
      <footer className="py-3 my-4">
        <ul className="nav justify-content-center border-bottom pb-3 mb-3">
          <li className="nav-item">
            <a href="/" className="nav-link px-2 text-body-secondary">
              Home
            </a>
          </li>
          <li className="nav-item">
            <a href="/about" className="nav-link px-2 text-body-secondary">
              About
            </a>
          </li>
          <li className="nav-item">
            <a href="/reviews" className="nav-link px-2 text-body-secondary">
              Reviews
            </a>
          </li>
          <li className="nav-item">
            <a href="/forum" className="nav-link px-2 text-body-secondary">
              Forum
            </a>
          </li>
          {userToken.token !== "" ? (
            <>
              <li className="nav-item">
                <button
                  onClick={handleLogout}
                  className="nav-link px-2 text-body-secondary"
                >
                  Logout
                </button>
              </li>
            </>
          ) : (
            <li className="nav-item">
              <a href="/login" className="nav-link px-2 text-body-secondary">
                Login
              </a>
            </li>
          )}
        </ul>
        <p className="text-center text-body-secondary">
          © 2024 RATE MY ADMIN. All rights reserved.
        </p>
        <LogoutConfirmationModal
          show={showModal}
          handleClose={handleCloseModal}
          handleLogout={handleConfirmLogout}
        />
      </footer>
    </div>
  );
};

export default Footer;
