import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";

const PrivacyPolicy = () => {
  return (
    <Container className="mt-5">
      <Row>
        <Col md={{ span: 8, offset: 2 }}>
          <Card>
            <Card.Body>
              <h1 className="text-center mb-4">Privacy Policy</h1>

              <h5>1. Introduction</h5>
              <p>
                Rate MY Admin is committed to protecting your privacy. This
                Privacy Policy explains how we collect, use, and share your
                personal data.
              </p>

              <h5>2. Information We Collect</h5>
              <p>
                We collect personal information when you create an account,
                submit reviews, or participate in forums. This includes your
                name, email address, and any other content you provide.
              </p>

              <h5>3. How We Use Your Information</h5>
              <p>
                We use your information to provide and improve our services, and
                to ensure the security of our platform. We may also use your
                information to communicate with you.
              </p>

              <h5>4. Data Sharing</h5>
              <p>
                We do not sell your personal data. We may share your information
                with service providers who assist in operating our platform. We
                may also share data if required by law.
              </p>

              <h5>5. Security</h5>
              <p>
                We use reasonable security measures to protect your data, but no
                method of transmission over the internet is 100% secure.
              </p>

              <h5>6. User Rights</h5>
              <p>
                You have the right to access, correct, or delete your personal
                data. Please contact us if you wish to exercise any of these
                rights.
              </p>

              <h5>7. Changes to This Policy</h5>
              <p>
                We may update this Privacy Policy from time to time. Any changes
                will be posted on this page.
              </p>

              <h5>8. Contact Us</h5>
              <p>
                If you have any questions about this Privacy Policy, please
                contact us at [email address].
              </p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default PrivacyPolicy;
