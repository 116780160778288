import React, { useEffect, useState } from "react";
import { Container, Button, Form, ListGroup, Spinner } from "react-bootstrap";
import {
  DeleteComment,
  GetRequest,
  PostRequest,
  UpdateRatee,
} from "../utils/apiCall";
import { useSelector } from "react-redux";
import { userDetail } from "../redux/userDetailSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { token } from "../redux/tokenSlice";
import StarRating from "../componenets/starRating";
import toast, { Toaster } from "react-hot-toast";
import { IoArrowBack } from "react-icons/io5";

const UserDetail = () => {
  const { state } = useLocation(); // Access passed state
  const user = state.user;
  const [rating, setRating] = useState(5);
  const [review, setReview] = useState("");
  const [initialReview, setInitialReview] = useState("");
  const [allReviews, setAllReviews] = useState([]);
  const [allRatings, setAllRatings] = useState([]);
  const [averageRating, setAverageRating] = useState(0);
  const [editMode, setEditMode] = useState(null);
  const [editRating, setEditRating] = useState(5);
  const [editReview, setEditReview] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showNewReview, setShowNewReview] = useState(false);
  const [adminActivity, setAdminActivity] = useState([]);
  const userType = useSelector(userDetail);
  const userToken = useSelector(token);
  const navigate = useNavigate();

  const fetchReviewsAndRatings = async () => {
    const resReviews = await GetRequest(
      `/api/home/allRateeReviews/${user._id}`
    );
    const reviews = resReviews.data.data;
    console.log("reviews ", reviews);
    const visibleReviews = reviews.filter((review) => {
      if (
        (userType._id === review?.userId?._id && userToken.token !== "") ||
        ((userType.role === "ADMIN" || userType.role === "OPERATOR") &&
          userToken.token !== "")
      ) {
        return true; // Show comment for the author or admin
      } else {
        return !review.isBlocked; // Show comment if it is not blocked
      }
    });
    const sortedReviews = visibleReviews.sort(
      (a, b) => new Date(b.date_created) - new Date(a.date_created)
    );
    setAllReviews(sortedReviews);

    // Fetch ratings
    const resRatings = await GetRequest(`/api/home/allRatings/${user._id}`);
    const ratings = resRatings.data.data;
    setAllRatings(ratings);

    // Calculate average rating
    const average =
      ratings.reduce((acc, r) => acc + r.rating, 0) / ratings.length;
    setAverageRating(average || 5);

    // Find and set user's own review if available
    const userReview = sortedReviews.find(
      (review) => review?.userId?._id === userType._id
    );
    if (userReview) {
      setShowNewReview(true);
      setReview(userReview.review);
      const userRating = ratings.find(
        (rating) => rating.userId === userType._id
      );
      if (userRating) {
        setRating(userRating.rating);
      }
    }
  };

  const fetchAdminActivity = async () => {
    const resActivity = await GetRequest(`/api/home/getAdminActivities`);
    // console.log("adminactivity data ==> ",resActivity);
    if (resActivity.status) {
      setAdminActivity(resActivity.data.data);
    }
  };

  const handleSave = async () => {
    setIsLoading(true);

    if (userToken.token !== "") {
      // Check if the user has already posted a review
      const existingReview = allReviews.find(
        (review) => review?.userId?._id === userType._id
      );

      if (existingReview) {
        const adminData = {
          activityType: "edit",
          reviewId: existingReview._id,
          previousContent: initialReview,
          updatedContent: review,
          adminId: userType._id,
        };
        // Update the existing review
        const reviewData = {
          review,
        };
        await UpdateRatee(
          reviewData,
          `/api/home/updateReview/${existingReview._id}`
        ).then((res) => {
          console.log("review updated ==> ", res);
          if (res?.data?.status) {
            toast.success(res?.data?.message);
            console.log("response of review api success ==> ", res);

            if (userType.role === "ADMIN") {
              PostRequest(adminData, "/api/home/logActivity").then((res) => {
                console.log("admin activity added ", res);
                toast.success(res?.data?.message);
                fetchAdminActivity();
              });
            }
          } else {
            console.log(
              "response of review api ==> ",
              res?.error?.response?.data?.message
            );
            toast.error(res?.error?.response?.data?.message);
          }
        });
      } else {
        // Create a new review
        const reviewData = {
          rateeUserId: user._id,
          userId: userType._id,
          review,
        };
        await PostRequest(reviewData, `/api/home/createReview`).then((res) => {
          console.log("response of review api call==> ", res);
          if (res?.data?.status) {
            console.log("response of review api success ==> ", res);
          } else {
            console.log(
              "response of review api ==> ",
              res?.error?.response?.data?.message
            );
            toast.error(res?.error?.response?.data?.message);
          }
        });
      }

      // Create or update rating
      const ratingData = {
        rateeUserId: user._id,
        userId: userType._id,
        rating,
      };
      await PostRequest(ratingData, `/api/home/createRating`);

      fetchReviewsAndRatings();
      setReview("");
    } else {
      navigate("/login");
    }
    setIsLoading(false);
  };

  const deleteReview = (id, initialReview) => {
    DeleteComment(`/api/home/deleteReview/${id}`).then((res) => {
      toast.success("Review Deleted Successfully");
      const adminData = {
        activityType: "delete",
        reviewId: id,
        previousContent: initialReview,
        updatedContent: "This review deleted successfully",
        adminId: userType._id,
      };
      if (userType.role === "ADMIN") {
        PostRequest(adminData, "/api/home/logActivity").then((res) => {
          console.log("admin activity added ", res);
          fetchAdminActivity();
        });
      }
      fetchReviewsAndRatings();
    });
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    // Get date components
    const year = date.getFullYear();
    const month = date.toLocaleString("en-US", { month: "long" });
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0");

    // Determine AM/PM and convert hour to 12-hour format
    const ampm = hours >= 12 ? "pm" : "am";
    const hour12 = hours % 12 || 12;

    // Add suffix to day (st, nd, rd, th)
    const daySuffix = (day) => {
      if (day > 3 && day < 21) return "th"; // Covers 11th - 20th
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };

    // Format the final date string
    return `${month} ${day}${daySuffix(
      day
    )}, ${year}, ${hour12}:${minutes} ${ampm}`;
  };

  const handleEdit = async (reviewId) => {
    setIsLoading(true);

    const reviewData = {
      review: editReview,
    };
    const adminData = {
      activityType: "edit",
      reviewId: reviewId,
      previousContent: initialReview,
      updatedContent: editReview,
      adminId: userType._id,
    };
    await UpdateRatee(reviewData, `/api/home/updateReview/${reviewId}`).then(
      (res) => {
        if (res?.data?.status) {
            toast.success(res?.data?.message);
            console.log("response of review api success ==> ", res);

            if (userType.role === "ADMIN") {
              PostRequest(adminData, "/api/home/logActivity").then((res) => {
                console.log("admin activity added ", res);
                fetchAdminActivity();
              });
            }
          } else {
            console.log(
              "response of review api ==> ",
              res?.error?.response?.data?.message
            );
            toast.error(res?.error?.response?.data?.message);
          }
      }
    );

    // Update rating if edited
    const ratingData = {
      rateeUserId: user._id,
      userId: userType._id,
      rating: editRating,
    };
    await PostRequest(ratingData, `/api/home/createRating`);

    setEditMode(null);
    fetchReviewsAndRatings();
    setReview(editReview);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchReviewsAndRatings();
    fetchAdminActivity();
  }, []);

  return (
    <Container className="min-vh-100">
      <h2 className="text-center mt-2">User Details</h2>
      <h5>{user.name || "User name"}</h5>
      <p>Institution: {user.institute || "N/A"}</p>
      <p>Average Rating: {averageRating.toFixed(1)}</p>
      <hr />
      <h5>All Reviews and Ratings</h5>
      <ListGroup>
        {allReviews
          .slice()
          .reverse()
          .map((review) => {
            const reviewRating =
              allRatings.find((rating) => rating.userId === review?.userId?._id)
                ?.rating || 1;
            const isEditedByAdmin = adminActivity.some(
              (activity) => activity.reviewId === review._id
            );
            return (
              <ListGroup.Item key={review._id}>
                {editMode === review._id ? (
                  <>
                    <Form.Group>
                      <Form.Label>Edit Rating</Form.Label>
                      <StarRating
                        rating={editRating}
                        setRating={setEditRating}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>Edit Review</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={2}
                        value={editReview}
                        onChange={(e) => setEditReview(e.target.value)}
                      />
                    </Form.Group>
                    <div className="d-flex justify-content-end mt-2">
                      {isLoading ? (
                        <Spinner
                          animation="border"
                          role="status"
                          variant="success"
                        ></Spinner>
                      ) : (
                        <Button
                          variant="primary"
                          onClick={() => handleEdit(review._id)}
                        >
                          Save
                        </Button>
                      )}
                      <Button
                        variant="secondary"
                        onClick={() => setEditMode(null)}
                        className="ml-2"
                      >
                        Cancel
                      </Button>
                    </div>
                  </>
                ) : (
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <div>
                        <StarRating rating={reviewRating} />
                      </div>
                      <div>
                        <strong>{review?.userId?.avatarName}</strong> -{" "}
                        {formatDate(review.date_created)}
                      </div>
                      <div>
                        {review.review}
                        {review.isBlocked && (
                          <span
                            style={{
                              fontSize: "0.8em",
                              color: "red",
                              marginLeft: "10px",
                            }}
                          >
                            Blocked
                          </span>
                        )}
                      </div>
                      {isEditedByAdmin && (
                        <div className="text-muted">
                          <small>Edited by admin</small>
                        </div>
                      )}
                    </div>
                    <div className="d-flex">
                      {userType.role === "ADMIN" && userToken.token !== "" ? (
                        <div>
                          <Button
                            variant="danger"
                            className="mx-1"
                            onClick={() => {
                              setInitialReview(review.review);
                              deleteReview(review._id, review.review);
                            }}
                          >
                            Delete
                          </Button>
                        </div>
                      ) : null}
                      {(userType._id === review?.userId?._id ||
                        userType.role === "ADMIN") &&
                      userToken.token !== "" ? (
                        <Button
                          variant="info"
                          onClick={() => {
                            setEditMode(review._id);
                            setEditRating(
                              allRatings.find(
                                (rating) =>
                                  rating.userId === review?.userId?._id
                              )?.rating || 5
                            );
                            setEditReview(review.review);
                            setInitialReview(review.review);
                          }}
                          className="ml-2"
                        >
                          Edit
                        </Button>
                      ) : null}
                    </div>
                  </div>
                )}
              </ListGroup.Item>
            );
          })}
      </ListGroup>
      {userToken.token !== "" && (
        <div className="mt-4">
          <h5>Add or Edit Review</h5>
          <Form.Group>
            <Form.Label>Rating</Form.Label>
            <StarRating rating={rating} setRating={setRating} />
          </Form.Group>
          <Form.Group>
            <Form.Label>Review</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={review}
              onChange={(e) => setReview(e.target.value)}
            />
          </Form.Group>
          <div className="d-flex justify-content-between mt-2">
            <Button variant="primary" onClick={() => navigate("/reviews")}>
              <IoArrowBack className="mx-1" />
              All Reviews
            </Button>
            {isLoading ? (
              <Spinner
                animation="border"
                role="status"
                variant="success"
              ></Spinner>
            ) : (
              <Button variant="primary" onClick={handleSave}>
                {showNewReview ? "Update Review" : "Add Review"}
              </Button>
            )}
          </div>
        </div>
      )}
      <Toaster />
    </Container>
  );
};

export default UserDetail;
