import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { token } from "../redux/tokenSlice";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import {
  DeleteComment,
  getAllRateeUsers,
  GetRequest,
  updateForumStatus,
} from "../utils/apiCall";
import { useNavigate, useLocation } from "react-router-dom";
import { userDetail } from "../redux/userDetailSlice";

function UserReviews() {
  const [searchQuery, setSearchQuery] = useState("");
  const [allUsers, setAllUsers] = useState([]);

  const [allForums, setAllForums] = useState([]);
  const [filteredForums, setFilteredForums] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const userToken = useSelector(token);
  const userType = useSelector(userDetail);
  const navigate = useNavigate();
  const location = useLocation(); // Use the useLocation hook to access location state
  const [activeButton, setActiveButton] = useState("reviews");

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
  };

  // Extract user from location state
  const user = location.state?.user;
  console.log("user details==>", user);

  const getAllForums = () => {
    setIsLoading(true);
    try {
      GetRequest("/api/home/allforums").then((res) => {
        if (res.status) {
          const sortedForums = res.data.data.sort(
            (a, b) => new Date(b.createDate) - new Date(a.createDate)
          );
          setAllForums(sortedForums);
          console.log("all forums ==>", sortedForums);

          setFilteredForums(sortedForums); // Initially, show all forums
        }
        setIsLoading(false);
      });
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  // Fetch all reviews on component mount or when userToken changes
  useEffect(() => {
    getAllReviews();
  }, [userToken]);

  // Fetch reviews based on userId from location state
  useEffect(() => {
    if (user._id) {
      getAllRateeUsers(`/api/home/getMyRateeUsers/${user._id}`)
        .then((response) => {
          if (response.status) {
            const sortedUsers = response.data.data.sort(
              (a, b) => new Date(b.date_created) - new Date(a.date_created)
            );
            setAllUsers(sortedUsers);
          } else {
            console.error("Error:", response.error);
          }
        })
        .catch((error) => {
          console.error("Error fetching users:", error);
        })
        .finally(() => {
          setIsLoading(false); // Set loading to false when API call completes
        });
      GetRequest(`/api/home/myAllForums/${user._id}`)
        .then((res) => {
          console.log("My all forums ==>", res.data.data, res.status);
          if (res.status) {
            setAllForums(res.data.data);
            setFilteredForums(res.data.data); // Initially, show all forums
          }
        })
        .catch((error) => {
          console.error("Error fetching forums:", error);
        })
        .finally(() => {
          setIsLoading(false); // Set loading to false when API call completes
        });
    }
  }, [user._id, userToken]);

  // Fetch all reviews
  const getAllReviews = () => {
    setIsLoading(true);
    getAllRateeUsers(`/api/home/getMyRateeUsers/${user._id}`)
      .then((response) => {
        if (response.status) {
          const sortedUsers = response.data.data.sort(
            (a, b) => new Date(b.date_created) - new Date(a.date_created)
          );
          setAllUsers(sortedUsers);
        } else {
          console.error("Error:", response.error);
        }
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      })
      .finally(() => {
        setIsLoading(false); // Set loading to false when API call completes
      });
  };

  // Handle activate/deactivate button click
  const handleStatusToggle = (forum) => {
    setIsLoading(true);
    const updatedStatus = forum.status === "active" ? "inactive" : "active";
    updateForumStatus(forum._id, updatedStatus)
      .then((res) => {
        if (res.status) {
          setAllForums((prevForums) =>
            prevForums.map((f) =>
              f._id === forum._id ? { ...f, status: updatedStatus } : f
            )
          );
          setFilteredForums((prevForums) =>
            prevForums.map((f) =>
              f._id === forum._id ? { ...f, status: updatedStatus } : f
            )
          );
        } else {
          console.error("Error updating forum status:", res.error);
        }
      })
      .catch((error) => {
        console.error("Error updating forum status:", error);
      })
      .finally(() => {
        setIsLoading(false); // Set loading to false when API call completes (success or error)
      });
  };

  // Handle search input change
  const handleSearchChange = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
  };

  // Handle search input change
  const handleSearchChange1 = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
    const filtered = allForums.filter((forum) =>
      forum.heading.toLowerCase().includes(query)
    );
    setFilteredForums(filtered);
  };

  const handleItemClick = (item) => {
    navigate(`/detail/${item._id}`, { state: { item } });
  };
  const handleEditClick = (item) => {
    navigate(`/create-forum`, { state: { item } });
  };

  const deleteForum = (id) => {
    DeleteComment(`/api/home/deleteForum/${id}`).then((res) => {
      // toast.success("Review Deleted Successfully")
      console.log("forum deleted ==> ", res);

      getAllForums();
    });
  };

  // Filter users based on search query
  const filteredUsers = allUsers.filter((user) =>
    user.name.toLowerCase().includes(searchQuery)
  );
  const formatDate = (dateString) => {
  const date = new Date(dateString);

  // Get date components
  const year = date.getFullYear();
  const month = date.toLocaleString("en-US", { month: "long" });
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, "0");

  // Determine AM/PM and convert hour to 12-hour format
  const ampm = hours >= 12 ? "pm" : "am";
  const hour12 = hours % 12 || 12;

  // Add suffix to day (st, nd, rd, th)
  const daySuffix = (day) => {
    if (day > 3 && day < 21) return "th"; // Covers 11th - 20th
    switch (day % 10) {
      case 1: return "st";
      case 2: return "nd";
      case 3: return "rd";
      default: return "th";
    }
  };

  // Format the final date string
  return `${month} ${day}${daySuffix(day)}, ${year}, ${hour12}:${minutes} ${ampm}`;
};


  // Handle user click
  const handleUserClick = (user) => {
    navigate(`/user/${user._id}`, { state: { user } });
  };

  // Render users
  const renderUsers = () => {
    if (isLoading) {
      return (
        <div
          className="text-center mt-4 d-flex align-items-center justify-content-center"
          style={{ height: 500 }}
        >
          <Spinner animation="border" role="status" variant="success"></Spinner>
        </div>
      );
    }
    if (filteredUsers.length === 0) {
      return (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ height: 380 }}
        >
          <p className="text-muted">No Review created yet.</p>
        </div>
      );
    }

    return (
      <ul
        className="list-group"
        style={filteredUsers.length < 7 ? { height: 480 } : {}}
      >
        {filteredUsers.map((user) => (
          <li
            key={user._id}
            className="list-group-item clickable"
            onClick={() => handleUserClick(user)}
            style={{ cursor: "pointer" }}
          >
            <div>
              {user.name}{" "}
              <span style={{ fontSize: 14 }}>
                ({user.institute ? user.institute : "Institute name"}{" "}
                {user.averageRating ? user.averageRating : 5})
              </span>
              <span
                style={{ fontSize: "0.8em", color: "grey", marginLeft: "10px" }}
              >
                {formatDate(user.date_created)}
              </span>
            </div>
            {/* <div style={{fontSize:12}}>{user.institute ? user.institute : "Institute name"}</div> */}
          </li>
        ))}
      </ul>
    );
  };
  // Render filtered forums
  const renderForums = () => {
    if (isLoading) {
      return (
        <div
          className="text-center mt-4 d-flex align-items-center justify-content-center"
          style={{ height: 480 }}
        >
          <Spinner animation="border" role="status" variant="success"></Spinner>
        </div>
      );
    }

    if (filteredForums.length === 0) {
      return (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ height: 480 }}
        >
          <p className="text-muted">No forum created yet.</p>
        </div>
      );
    }

    return (
      <ul
        className="list-group"
        style={filteredForums.length < 16 ? { height: 480 } : {}}
      >
        {filteredForums.map((item) => (
          <li
            key={item._id}
            onClick={() => handleItemClick(item)}
            className="list-group-item d-flex justify-content-between align-items-center"
            style={{ cursor: "pointer" }}
          >
            <div>
              <div>{item.heading}</div>
              <div
                style={{
                  fontSize: 12,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                dangerouslySetInnerHTML={{ __html: item.description }}
              />
            </div>

            {(userType.role === "ADMIN" || userType.role === "OPERATOR") &&
              userToken.token !== "" && (
                <Row>
                  <Button
                    style={{ width: 100 }}
                    variant={item.status === "active" ? "danger" : "success"}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleStatusToggle(item);
                    }}
                  >
                    {item.status === "active" ? "Deactivate" : "Activate"}
                  </Button>
                  <Button
                    style={{ width: 100, marginLeft: 5, marginRight: 5 }}
                    variant="secondary"
                    size="sm"
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent the item click handler from firing
                      handleEditClick(item);
                    }}
                  >
                    Edit
                  </Button>
                  <Button
                    style={{ width: 100 }}
                    variant="danger"
                    size="sm"
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent the item click handler from firing
                      deleteForum(item._id);
                    }}
                  >
                    Delete
                  </Button>
                </Row>
              )}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div className="container">
      {/* User Details Section */}
      {user && (
        <div className="card mb-3 mt-3">
          <div className="card-body">
            <h5 className="card-title">{user.name}</h5>
            <p className="card-text">
              <strong>Email:</strong> {user.email}
            </p>
            <p className="card-text">
              <strong>Role:</strong> {user.role}
            </p>
            <p className="card-text">
              <strong>Avatar Name:</strong> {user.avatarName}
            </p>
          </div>
        </div>
      )}

      <Row className="justify-content-center mt-5 mb-5">
        <Button
          variant="outline-secondary"
          className="w-25 mx-2"
          style={{
            backgroundColor: activeButton === "reviews" ? "#d3d3d3" : "",
          }}
          onClick={() => handleButtonClick("reviews")}
        >
          Reviews
        </Button>
        <Button
          variant="outline-secondary"
          className="w-25 mx-2"
          style={{
            backgroundColor: activeButton === "forums" ? "#d3d3d3" : "",
          }}
          onClick={() => handleButtonClick("forums")}
        >
          Forums
        </Button>
      </Row>
      {activeButton === "reviews" ? (
        <>
          <Row className="justify-content-left mt-4">
            <Col md={6}>
              <Form.Control
                type="text"
                placeholder="Search  Reviews..."
                value={searchQuery}
                onChange={handleSearchChange}
              />
            </Col>
            {/* <Col md={3}>
          <Button as={Link} to={userToken.token !== "" ? "/create-user" : "/login"} variant="primary">
            New Review
          </Button>
        </Col> */}
          </Row>
          <section className="card-sec my-3">
            <div className="row">
              <div className="col">{renderUsers()}</div>
            </div>
          </section>
        </>
      ) : (
        <>
          <Row className="justify-content-left mt-4">
            <Col md={6}>
              <Form.Control
                type="text"
                placeholder="Search for a Discussion topic..."
                value={searchQuery}
                onChange={handleSearchChange1}
              />
            </Col>
          </Row>
          <section className="card-sec my-3">
            <div className="row">
              <div className="col">{renderForums()}</div>
            </div>
          </section>
        </>
      )}
    </div>
  );
}

export default UserReviews;
