
// import './App.css';
// import { Header } from './componenets/layout/header';
// import RouterConfig from './router/RouterConfig';

// function App() {
//   return (
//     <div >

//       <Header />
//       <RouterConfig />
//     </div>
//   );
// }

// export default App;
import { persistStore } from "redux-persist";
import "./App.css";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import RouterConfig from "./router/RouterConfig";
import store from "./redux/store";

function App() {

  let persistor = persistStore(store);

  return (
      <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <RouterConfig />
      </PersistGate>
    </Provider>
  );
}

export default App;

