// CreateUser.js
import React, { useState } from "react";
import { Button, Form, Container, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { PostRequest } from "../utils/apiCall";
import { useSelector } from "react-redux";
import { userDetail } from "../redux/userDetailSlice";
import { Toaster, toast } from "react-hot-toast";

const CreateUser = () => {
  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const navigate = useNavigate();
  const userType = useSelector(userDetail);
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = (e) => {
  setIsLoading(true)
    e.preventDefault();
    const data = {
    "rateeId": userType._id,
    "name": name,
    "institute": category,
    "averageRating":5,
    }

    PostRequest(data, "/api/home/createRateeUser").then((res)=>{
      
      if (res.data.status) {
         const user = res.data.data;
          toast.success("Review Created Successfully")
          setTimeout(() => {
         navigate(`/user/${res.data.data._id}`, { state: { user } });
          }, 1000);
      } else {
         toast.error("Something went WRONG")
        console.log(res);
      }

    }).catch((e)=>console.log(e)
    ).finally(() => {
    setIsLoading(false)
  })
   
    // Add logic to save the new user (e.g., update state, make API call)
  };

  return (
    <Container className="mt-5" style={{marginBottom:"16%"}}>
      <h2>Create New Review</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formName">
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group controlId="formCategory" className="mt-2">
          <Form.Label>Institution</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Institution"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          />
        </Form.Group>
        <Button variant="primary" type="submit" className="mt-4">
           {isLoading?<Spinner animation="border" role="status" variant="success" /> :"Create Review"}
        </Button>
      </Form>
    <Toaster />
    </Container>
  );
};

export default CreateUser;
