import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { DeleteComment, GetMyForums, GetRequest } from "../utils/apiCall";
import { useSelector } from "react-redux";
import { userDetail } from "../redux/userDetailSlice";

function MyForum() {
  const [searchQuery, setSearchQuery] = useState("");
  const [allForums, setAllForums] = useState([]);
  const [filteredForums, setFilteredForums] = useState([]);
  const navigate = useNavigate();
  const userType = useSelector(userDetail);
  const [isLoading, setIsLoading] = useState(true);
   const MAX_LENGTH = 150;

// Function to truncate text to a maximum length
const truncateText = (text, maxLength) => {
  if (text.length > maxLength) {
    return text.slice(0, maxLength) + '...';
  }
  return text;
};
  useEffect(() => {
    getAllForums();
  }, []);

  const getAllForums = () => {
    setIsLoading(true);
    try {
      GetRequest(`/api/home/myAllForums/${userType._id}`).then((res) => {
        console.log("My all forums ==>", res.data.data, res.status);
        if (res.status) {
          setAllForums(res.data.data);
          setFilteredForums(res.data.data); // Initially, show all forums
        }
        setIsLoading(false);
      });
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  // Handle search input change
  const handleSearchChange = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
    const filtered = allForums.filter((forum) =>
      forum.heading.toLowerCase().includes(query)
    );
    setFilteredForums(filtered);
  };

  const handleItemClick = (item) => {
    navigate(`/detail/${item._id}`, { state: { item } });
  };
  const handleEditClick = (item) => {
    navigate(`/create-forum`, { state: { item } });
  };

  const deleteForum = (id) => {
    DeleteComment(`/api/home/deleteForum/${id}`).then((res) => {
      // toast.success("Review Deleted Successfully")
      console.log("forum deleted ==> ", res);

      getAllForums();
    });
  };
  // Render filtered forums
  const renderForums = () => {
    if (isLoading) {
      return (
        <div
          className="text-center mt-4 d-flex align-items-center justify-content-center"
          style={{ height: 480 }}
        >
          <Spinner animation="border" role="status" variant="success"></Spinner>
        </div>
      );
    }

    if (filteredForums.length === 0) {
      return (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ height: 480 }}
        >
          <p className="text-muted">No forum created yet.</p>
        </div>
      );
    }

    return (
      <ul
        className="list-group"
        style={filteredForums.length < 16 ? { height: 480 } : {}}
      >
        {filteredForums.map((item) => (
          <li
            key={item._id}
            onClick={() => handleItemClick(item)}
            className="list-group-item d-flex justify-content-between align-items-center"
            style={{ cursor: "pointer" }}
          >
            <div>
              <div>{item.heading}</div>
              <div
                style={{
                  fontSize: 12,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                dangerouslySetInnerHTML={{ __html: truncateText(item.description, MAX_LENGTH)  }}
              />
            </div>
            <div>
              <Button
                style={{ width: 100, marginLeft: 5, marginRight: 5 }}
                variant="secondary"
                size="sm"
                onClick={(e) => {
                  e.stopPropagation(); // Prevent the item click handler from firing
                  handleEditClick(item);
                }}
              >
                Edit
              </Button>
              <Button
                style={{ width: 100 }}
                variant="danger"
                size="sm"
                onClick={(e) => {
                  e.stopPropagation(); // Prevent the item click handler from firing
                  deleteForum(item._id);
                }}
              >
                Delete
              </Button>
            </div>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div className="container">
      <Row className="justify-content-left mt-4">
        <Col md={6}>
          <Form.Control
            type="text"
            placeholder="Search for a Discussion topic..."
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </Col>
        <Col md={3}>
          <Button as={Link} to="/create-forum" variant="primary">
            Create Forum Topic
          </Button>
        </Col>
      </Row>
      <section className="card-sec my-3">
        <div className="row">
          <div className="col">{renderForums()}</div>
        </div>
      </section>
    </div>
  );
}

export default MyForum;
