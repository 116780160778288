import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { token } from "../redux/tokenSlice";
import { Button, Col, Form, Row, Container } from "react-bootstrap";
import { FaSearch } from "react-icons/fa"; // Import the search icon
import { Toaster } from "react-hot-toast";

function Dashboard() {
  const [searchQuery, setSearchQuery] = useState("");
  const userToken = useSelector(token);
  const navigate = useNavigate();

  // Handle search input change
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  // Handle search submit
  const handleSearchSubmit = () => {
    if (searchQuery !== "") {
      navigate(`/search-results/${searchQuery}`);
    }
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearchSubmit();
    }
  };

  return (
    <Container
      className="d-flex flex-column align-items-center  "
      style={{ height: 580 }}
    >
      <div className="pricing-header mt-4  px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
        <h1 className="display-4">Admin Review</h1>
        <p className="lead">Rate your admin confidentially </p>
      </div>
      <Row className="justify-content-center w-100 pt-5 ">
        <Col md={8} lg={16} xl={16}>
          <Form.Group style={{ position: "relative" }}>
            <Form.Control
              type="text"
              size="lg"
              placeholder="Search for your Admin/institution here"
              value={searchQuery}
              onChange={handleSearchChange}
              onKeyDown={handleKeyDown}
              className="text-left"
              style={{
                width: "100%",
                height: 60,
                borderRadius: 45,
                paddingLeft: 60,
                textAlign: "left", // Add this line to align text to the left
              }}
            />
            <FaSearch
              style={{
                position: "absolute",
                left: 20,
                top: 32,
                transform: "translateY(-50%)",
              }}
              size={22}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mt-4 w-100">
        <Col className="text-center  w-100">
          <Button
            onClick={handleSearchSubmit}
            variant="primary"
            style={{ width: 300 }}
          >
            Search
          </Button>
        </Col>
      </Row>
      <Row className="mt-4 w-100 ">
        <Col className="text-center w-100">
          <Button
            as={Link}
            to={userToken.token !== "" ? "/create-user" : "/login"}
            variant="primary"
            style={{ width: 300 }}
          >
            Create a Review
          </Button>
        </Col>
      </Row>
      <Toaster />
    </Container>
  );
}

export default Dashboard;
