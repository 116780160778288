// UserDetailsModal.js
import React, { useEffect, useState } from "react";
import { Modal, Button, Form, ListGroup } from "react-bootstrap";
import StarRating from "./starRating";
import { GetRequest, PostRequest, UpdateRatee } from "../utils/apiCall";
import { useSelector } from "react-redux";
import { userDetail } from "../redux/userDetailSlice";

const UserDetailsModal = ({ show, handleClose, user, getAllReviews }) => {
  const [rating, setRating] = useState(user.rating || 5);
  const [review, setReview] = useState(user.review || "");
  const [allReviews, setAllReviews] = useState([]);
  const [averageRating, setAverageRating] = useState(0);
  const [editMode, setEditMode] = useState(null);
  const [editRating, setEditRating] = useState(5);
  const [editReview, setEditReview] = useState("");
  const userType = useSelector(userDetail);

  const fetchReviews = async () => {
    const res = await GetRequest(`/api/home/allRateeReviews/${user._id}`);
    const reviews = res.data.data;

    // Sort reviews by creation date in descending order
    const sortedReviews = reviews.sort((a, b) => new Date(b.date_created) - new Date(a.date_created));

    setAllReviews(sortedReviews);

    const totalRating = sortedReviews.reduce((sum, review) => sum + review.rating, 0);
    const average = sortedReviews.length ? totalRating / sortedReviews.length : 0;
    const roundedAverage = Math.round(average);
    setAverageRating(Math.min(Math.max(roundedAverage, 0), 5));
  };
  console.log("all reviews ==> ",allReviews);

  const handleSave = async () => {
    const data = {
      rateeUserId: user._id,
      userId: userType._id,
      rating,
      review,
    };
    await PostRequest(data, `/api/home/createReview`);
    handleClose();
    getAllReviews();
    fetchReviews();
  };

  const handleEdit = async (reviewId) => {
    const data = {
      rating: editRating,
      review: editReview,
    };
    await UpdateRatee(data, `/api/home/updateReview/${reviewId}`);
    setEditMode(null);
    fetchReviews();
  };

  useEffect(() => {
    fetchReviews();
  }, []);

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>User Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5>{user.name}</h5>
        <p>Institution: {user.institute || "N/A"}</p>
        <p>Average Rating: {averageRating}</p>
        <Form.Group>
          <Form.Label>Rating</Form.Label>
          <StarRating rating={rating} setRating={setRating} />
        </Form.Group>
        <Form.Group>
          <Form.Label>Review</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={review}
            onChange={(e) => setReview(e.target.value)}
          />
        </Form.Group>
        <div className="d-flex justify-content-end mt-2">
          <Button variant="primary" onClick={handleSave}>
            Save
          </Button>
        </div>
        <hr />
        <h5>All Reviews</h5>
        <ListGroup>
          {allReviews.map((review) => (
            <ListGroup.Item key={review._id}>
              {editMode === review._id ? (
                <>
                  <Form.Group>
                    <Form.Label>Edit Rating</Form.Label>
                    <StarRating rating={editRating} setRating={setEditRating} />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Edit Review</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={2}
                      value={editReview}
                      onChange={(e) => setEditReview(e.target.value)}
                    />
                  </Form.Group>
                  <div className="d-flex justify-content-end mt-2">
                    <Button
                      variant="primary"
                      onClick={() => handleEdit(review._id)}
                    >
                      Save
                    </Button>
                    <Button
                      variant="secondary"
                      onClick={() => setEditMode(null)}
                      className="ml-2"
                    >
                      Cancel
                    </Button>
                  </div>
                </>
              ) : (
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <strong>Rating:</strong> {review.rating}
                    <p>{review.review}</p>
                  </div>
                  {userType._id === review.userId._id && (
                    <Button
                      variant="secondary"
                      onClick={() => {
                        setEditMode(review._id);
                        setEditRating(review.rating);
                        setEditReview(review.review);
                      }}
                    >
                      Edit
                    </Button>
                  )}
                </div>
              )}
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UserDetailsModal;
