import React from "react";
import { Container, Row, Col, Card } from 'react-bootstrap';

function About() {
  return (
     <Container style={{marginBottom:"4%"}}>
      <Row className="my-4">
        <Col>
          <h1>About RATE MY ADMIN</h1>
          <p>
            Welcome to RATE MY ADMIN, a platform dedicated to providing a space for users to rate and review their administrators. Whether you're a student, employee, or a member of an organization, you can share your experiences and help others make informed decisions.
          </p>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Card className="mb-4">
            <Card.Body>
              <Card.Title>Purpose</Card.Title>
              <Card.Text>
                Our mission is to bring transparency and accountability to administrative roles by allowing users to provide honest feedback and ratings. We believe in the power of collective experiences to drive improvement and recognition.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card className="mb-4">
            <Card.Body>
              <Card.Title>Features</Card.Title>
              <Card.Text>
                - Search for admins by name or institution<br/>
                - Read reviews and ratings from other users<br/>
                - Submit your own reviews and ratings<br/>
                - View overall ratings and feedback trends
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Card className="mb-4">
            <Card.Body>
              <Card.Title>How It Works</Card.Title>
              <Card.Text>
                Simply search for an admin using our search bar. If you find the admin you're looking for, you can read existing reviews and ratings. To submit your own review, click on the 'Rate' button and fill out the form with your feedback and rating.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card className="mb-4">
            <Card.Body>
              <Card.Title>Contact Us</Card.Title>
              <Card.Text>
                If you have any questions, feedback, or need support, please contact us at: <br/>
                Email: support@ratemyadmin.com<br/>
                Phone: +123-456-7890
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default About;
