const PATH = {
  NOPAGE: "*",

  //  AUTH PATHS
  Home: "/",
  About: "/about",
  Forum: "/forum",
  CreateForum: "/create-forum",
  CreateUser: "/create-user",
  LogIn: "/login",
  Settings: "/settings",
  Reviews: "/reviews",
  MyReviews: "/my-reviews",
  UserReviews: "/user-reviews",
  AllUsers: "/allUsers",
  MyAllForums: "/myAllForums",
  BlockedKeywords: "/blockedKeywords",
  AllForums: "/allForums",
  AdminActivityLog: "/adminActivityLog",
  Categories: "/categories",
  VerifyEmail: "/verify-email",
  TermsOfUse: "/terms-of-use",
  PrivacyPolicy: "/privacy-policy",
};
export default PATH;
