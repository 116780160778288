import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { token } from "../redux/tokenSlice";
import { GetAllUsers, PostRequest, UpdateRatee } from "../utils/apiCall";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function AllUsers() {
  const [searchQuery, setSearchQuery] = useState("");
  const [allUsers, setAllUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(15); // Number of items per page
  const [showModal, setShowModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [userDetails, setUserDetails] = useState({ name: "", email: "" });
  const userToken = useSelector(token);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const fetchAllUsers = () => {
    setIsLoading(true);
    GetAllUsers("/api/auth/allUsers", userToken.token)
      .then((res) => {
        if (res.status) {
          setAllUsers(res.data.data);
        } else {
          console.log(res.error);
        }
      })
      .catch((error) => {
        console.error("Error updating forum status:", error);
      })
      .finally(() => {
        setIsLoading(false); // Set loading to false when API call completes (success or error)
      });
  };

  useEffect(() => {
    fetchAllUsers();
  }, [userToken]);

  // Function to handle search input change
  const handleSearchChange = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
    setCurrentPage(1); // Reset to first page after search
  };

  // Function to paginate data based on current page
  const paginate = (data) => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return data.slice(startIndex, endIndex);
  };

  // Function to filter data based on search query
  const filteredData = allUsers.filter((user) =>
    user.name.toLowerCase().includes(searchQuery)
  );

  // Paginated data based on filtered results
  // const paginatedData = paginate(filteredData);

  // Handler for block button
  const handleBlockUser = (userId) => {
    const data = {};
    PostRequest(data, `/api/auth/blockUnblockUser/${userId}`).then((res) => {
      if (res.status) {
        fetchAllUsers();
      } else {
        console.log(res.error);
      }
    });
  };

  const handleUserClick = (user) => {
    navigate("/user-reviews", { state: { user } }); // Pass state with userId
  };

  const handleUpdateUserClick = (user) => {
    setSelectedUser(user);
    setUserDetails({ name: user.name, email: user.email });
    setShowModal(true);
  };

  // Handler for updating user details
  const handleUpdateUser = () => {
    UpdateRatee(userDetails, `/api/auth/updateUser/${selectedUser._id}`)
      .then((res) => {
        if (res.status) {
          // Update the user list with the new details
          setAllUsers((prevUsers) =>
            prevUsers.map((user) =>
              user._id === selectedUser._id ? { ...user, ...userDetails } : user
            )
          );
          setShowModal(false);
        } else {
          console.log(res.error);
        }
      })
      .catch((error) => {
        console.error("Error updating user:", error);
      });
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  // Example data rendering logic (adjust as per your data structure)
  const renderData = () => {
    if (isLoading) {
      return (
        <div
          className="text-center mt-4 d-flex align-items-center justify-content-center"
          style={{ height: 500 }}
        >
          <Spinner animation="border" role="status" variant="success"></Spinner>
        </div>
      );
    }
    return (
      <ul className="list-group">
        {filteredData.map((user) => (
          <li
            key={user._id}
            className="list-group-item d-flex justify-content-between align-items-center"
            style={{ cursor: "pointer" }}
          >
            <div className="w-75" onClick={() => handleUserClick(user)}>
              {capitalizeFirstLetter(user.name)}
            </div>
            <div>
              <Button
                className="btn  btn-sm me-2"
                variant={user.isBlocked ? "success" : "danger"}
                onClick={() => handleBlockUser(user._id)}
              >
                {user.isBlocked ? "Unblock" : "Block"}
              </Button>
              <button
                className="btn btn-primary btn-sm"
                onClick={() => handleUpdateUserClick(user)}
              >
                Update
              </button>
            </div>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div className="container">
      <div className="row justify-content-left mt-4">
        <div className="col-md-6">
          <input
            type="text"
            className="form-control"
            placeholder="Search user"
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </div>
      </div>
      <section className="card-sec my-3">
        <div className="row">
          <div className="col">{renderData()}</div>
        </div>
      </section>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Update User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter name"
                value={userDetails.name}
                onChange={(e) =>
                  setUserDetails({ ...userDetails, name: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group controlId="formEmail" className="mt-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                value={userDetails.email}
                onChange={(e) =>
                  setUserDetails({ ...userDetails, email: e.target.value })
                }
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleUpdateUser}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default AllUsers;
