import React from "react";
import { Outlet } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { Header } from "./header";
import Sidebar from "./sideBar";
import { useSelector } from "react-redux";
import { userDetail } from "../../redux/userDetailSlice";
import { token } from "../../redux/tokenSlice";
import AdminHeader from "./adminHeader";
import Footer from "../footer";

function MyLayout() {


  const userType = useSelector(userDetail);
  const userToken = useSelector(token);

  return (
    <div>
        {(userType.role === "ADMIN" || userType.role === "OPERATOR")&& userToken.token !== "" ?null:
      <Header />}
      <Container fluid>
        <Row>
        {(userType.role === "ADMIN" || userType.role === "OPERATOR") && userToken.token !== "" ?
          <Col xs={2} className="p-0">
            <Sidebar />
          </Col>:null
        }
          <Col xs={(userType.role === "ADMIN" || userType.role === "OPERATOR") && userToken.token !== "" ?10:12} className="p-0">
            <div className="main-content">
             {(userType.role === "ADMIN" || userType.role === "OPERATOR") && userToken.token !== "" ?
              <AdminHeader />:null
             }
              <Outlet />
            </div>
          </Col>
        </Row>
      </Container>
        {(userType.role === "ADMIN" || userType.role === "OPERATOR") && userToken.token !== "" ?null:
      <Footer />}
    </div>
  );
}

export default MyLayout;
