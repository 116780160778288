import React, { useEffect, useState } from "react";
import {
  Form,
  Button,
  Container,
  Row,
  Col,
  Card,
  Spinner,
} from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useSelector } from "react-redux";
import { userDetail } from "../redux/userDetailSlice";
import { GetRequest, PostRequest, UpdateRatee } from "../utils/apiCall";
import { useNavigate, useLocation } from "react-router-dom";
import { Toaster, toast } from "react-hot-toast";
import { FaChevronDown } from "react-icons/fa";

function CreateForum() {
  const [heading, setHeading] = useState("");
  const [description, setDescription] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [initialHeading, setInitialHeading] = useState("");
  const [initialDescription, setInitialDescription] = useState("");
  const [forumId, setForumId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const userType = useSelector(userDetail);
  const navigate = useNavigate();
  const location = useLocation();
  const charLimit = 3000;
  const [hasShownError, setHasShownError] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const forumToEdit = location.state?.item;
    if (forumToEdit) {
      setIsEdit(true);
      setHeading(forumToEdit.heading);
      setDescription(forumToEdit.description);
      setInitialHeading(forumToEdit.heading); // Save initial heading
      setInitialDescription(forumToEdit.description);
      setForumId(forumToEdit._id);
      setSelectedCategory(
        forumToEdit.category ? forumToEdit.category : "General"
      );
    }
      fetchCategories();
  }, [location.state]);

  const fetchCategories = async () => {
    try {
      const response = await GetRequest("/api/home/getCategories");
      console.log("all categories ==> ",response.data.data);
      
      if (response.status) {
        setCategories(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const handleHeadingChange = (e) => {
    setHeading(e.target.value);
  };

  // Handle category change
  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const handleDescriptionChange = (value) => {
    const plainText = value.replace(/<[^>]+>/g, "");

    if (plainText.length <= charLimit) {
      setDescription(value);
      setHasShownError(false); // Reset error flag when input is within the limit
    } else {
      if (!hasShownError) {
        toast.error("Description cannot exceed 3000 characters.");
        setHasShownError(true); // Set the flag to true to avoid repeated errors
      }
      // Stop further updates by not setting the description
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const data = {
      heading,
      description,
      category: selectedCategory,
      userId: userType._id, // Replace with a valid user ID
    };
    const updatedData = {
      heading: heading,
      description: description,
      category: selectedCategory,
    };
    const adminData = {
      forumId: forumId,
      previousContent: `Heading : ${initialHeading}, Description : ${initialDescription}`,
      updatedContent: `heading ${heading}, description ${description}`,
      adminId: userType._id,
    };

    if (isEdit) {
      UpdateRatee(updatedData, `/api/home/updateForum/${forumId}`)
        .then((res) => {
          // console.log("forum updated ==> ", res);
          if (res.data.status) {
            if (userType.role === "ADMIN") {
              PostRequest(adminData, "/api/home/logActivity").then((res) => {
                console.log("adminActivity ==> ", res);
              });
            }
            toast.success(`Forum Updated Successfully`)
            setTimeout(() => {
              
            navigate("/forum");
            }, 1000);
          }
          if (res.error) {
            toast.error(res.error.message);
          }
        })
        .catch((error) => {
          console.error("Error updating forum status:", error);
        })
        .finally(() => {
          setIsLoading(false); // Set loading to false when API call completes (success or error)
        });
    } else {
      PostRequest(data, "/api/home/createForum")
        .then((res) => {
          console.log("forum created ==> ", res);
          if (res.data.status) {
            
            toast.success(`Forum Created Successfully`)
            setTimeout(() => {
              
            navigate("/forum");
            }, 1000);
          }
          if (res.error) {
            toast.error(res.error.error.message);
          }
        })
        .catch((error) => {
          console.error("Error updating forum status:", error);
        })
        .finally(() => {
          setIsLoading(false); // Set loading to false when API call completes (success or error)
        });
    }
  };

  
  return (
    <Container className="mt-5">
      <Row className="justify-content-center">
        <Col md={8}>
          <Card className="p-4 shadow-sm">
            <Card.Body>
              <h1 className="text-center mb-4">
                {isEdit ? "Edit Forum" : "Create Forum"}
              </h1>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formHeading">
                  <Form.Label>Heading</Form.Label>
                  <Form.Control
                    type="text"
                    value={heading}
                    onChange={handleHeadingChange}
                    placeholder="Enter the heading"
                  />
                </Form.Group>
                {/* {userType.role === "ADMIN" && ( */}
                  <Col md={4} className="dropdown-select mt-3">
                    <Form.Control
                      as="select"
                      value={selectedCategory}
                      onChange={handleCategoryChange}
                      style={{ cursor: "pointer" }}
                    >
                      <option value="">All Categories</option>
                      {categories.map((category) => (
                        <option key={category._id} value={category.name}>
                          {category.name}
                        </option>
                      ))}
                    </Form.Control>
                    <FaChevronDown className="dropdown-icon" />
                  </Col>
                {/* )} */}
                <Form.Group controlId="formDescription" className="mt-3">
                  <Form.Label>Description</Form.Label>
                  <ReactQuill
                    value={description}
                    onChange={handleDescriptionChange}
                    placeholder="Enter the description"
                    theme="snow"
                  />
                </Form.Group>
                <Button variant="primary" type="submit" className="mt-4 w-100">
                  
                  {isLoading?<Spinner animation="border" role="status" variant="success"></Spinner>: isEdit ? "Update" : "Submit"}
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Toaster />
    </Container>
  );
}

export default CreateForum;
