import React, { useEffect, useState } from "react";
import { Button, Form, Container, Spinner } from "react-bootstrap";
import { FaTimes } from "react-icons/fa"; // Using react-icons for the cross icon
import { DeleteComment, GetRequest, PostRequest } from "../utils/apiCall";

function BlockKeywords() {
  const [keywords, setKeywords] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // Handle input change
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  // Handle add keyword
  const handleAddKeyword = () => {
    const newKeyword = {
      _id: "6699234b2376b3b99d61f2dre",
      keyword: inputValue.trim(),
      createdAt: "2024-07-18T14:14:35.654Z",
      updatedAt: "2024-07-18T14:14:35.654Z",
      __v: 0,
    };
    if (inputValue.trim() !== "" && !keywords.includes(inputValue.trim())) {
      setKeywords([...keywords, newKeyword]);
      const data = {
        keyword: inputValue.trim(),
      };
      setIsLoading(true);
      PostRequest(data, "api/home/addBlockedKeywords")
        .then((res) => {
          console.log("blocked keywords ==>", res);
        })
        .catch((error) => {
          console.error("Error updating forum status:", error);
        })
        .finally(() => {
          setIsLoading(false); // Set loading to false when API call completes (success or error)
        });
      setInputValue("");
    }
  };

  // Handle delete keyword
  const handleDeleteKeyword = (keywordToDelete) => {
    setIsLoading(true);
    setKeywords(
      keywords.filter((keyword) => keyword._id !== keywordToDelete._id)
    );
    DeleteComment(`/api/home/deleteKeyword/${keywordToDelete._id}`)
      .then((res) => {
        console.log("delete keword ==> ", res);
      })
      .catch((error) => {
        console.error("Error updating forum status:", error);
      })
      .finally(() => {
        setIsLoading(false); // Set loading to false when API call completes (success or error)
      });
  };

  const getAllKeywords = () => {
    setIsLoading(true);
    GetRequest("api/home/getBlockedKeywords")
      .then((res) => {
        if (res.status) {
          setKeywords(res.data.data);
        }
      })
      .catch((error) => {
        console.error("Error updating forum status:", error);
      })
      .finally(() => {
        setIsLoading(false); // Set loading to false when API call completes (success or error)
      });
  };
  useEffect(() => {
    getAllKeywords();
  }, []);

  if (isLoading) {
    return (
      <div
        className="text-center mt-4 d-flex align-items-center justify-content-center"
        style={{ height: 500 }}
      >
        <Spinner animation="border" role="status" variant="success"></Spinner>
      </div>
    );
  }

  return (
    <Container className="d-flex flex-column mt-5 vh-100">
      <Form className="w-50">
        <Form.Group className="mb-3">
          <Form.Label>Add Keywords</Form.Label>
          <Form.Control
            type="text"
            value={inputValue}
            onChange={handleInputChange}
            placeholder="Enter a keyword"
          />
        </Form.Group>
        <Button variant="primary" onClick={handleAddKeyword}>
          Add Keyword
        </Button>
      </Form>
      <div className="mt-3 d-flex flex-wrap w-75">
        {keywords.map((keyword, index) => (
          <Button
            key={index}
            variant="secondary"
            className="m-1 d-flex align-items-center"
          >
            {keyword.keyword}
            <FaTimes
              className="ms-2"
              style={{ cursor: "pointer" }}
              onClick={() => handleDeleteKeyword(keyword)}
            />
          </Button>
        ))}
      </div>
    </Container>
  );
}

export default BlockKeywords;
