import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  ListGroup,
  Modal,
  Spinner,
} from "react-bootstrap";
import {
  DeleteComment,
  GetRequest,
  PostRequest,
  updateForumStatus,
  UpdateRatee,
} from "../utils/apiCall";
import { useSelector } from "react-redux";
import { userDetail } from "../redux/userDetailSlice";
import { token } from "../redux/tokenSlice";
import { Toaster, toast } from "react-hot-toast";
import { FaReply, FaThumbsDown, FaThumbsUp } from "react-icons/fa";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";

const ForumDetailPage = () => {
  const location = useLocation();
  const { id } = useParams();
  const { item } = location.state || {}; // Access item from location state

  const [comments, setComments] = useState([]);
  const [commentText, setCommentText] = useState("");
  const [editIndex, setEditIndex] = useState(null);
  const [editText, setEditText] = useState("");
  const [initialText, setInitialText] = useState("");
  const [showEditModal, setShowEditModal] = useState(false);
  const [showReplies, setShowReplies] = useState("");
  const [replyText, setReplyText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [editingReplyId, setEditingReplyId] = useState(null);
  const [replyContent, setReplyContent] = useState("");
  const [initReplyContent, setInitReplyContent] = useState("");
  const [deletingReplyId, setDeletingReplyId] = useState(null);
  const [forum, setForum] = useState(item);
  const [adminActivity, setAdminActivity] = useState([]);

  const navigate = useNavigate();
  const userType = useSelector(userDetail);
  const userToken = useSelector(token);

  useEffect(() => {
    setForum(item);
    getAllComments();
    fetchAdminActivity();
  }, []);

  // Render item details or handle no item found scenario
  if (!forum) {
    return <div>No forum found</div>;
  }

  const handleCommentChange = (e) => {
    setCommentText(e.target.value);
  };

  const handleCommentSubmit = (e) => {
    e.preventDefault();
    if (commentText.trim() === "") {
      toast.error("Please add comment");
    } else {
      if (commentText.trim() !== "") {
        setComments([...comments, { comment: commentText }]);
        setCommentText("");
      }
      const data = {
        comment: commentText,
        userName: userType.avatarName,
        userId: userType._id,
        forumId: forum._id,
      };
      PostRequest(data, "/api/home/comments").then((res) => {
        console.log("commented", res);
        if (res?.data?.status) {
          toast.success(res?.data?.message);
        } else {
          console.log(
            "response of review api ==> ",
            res?.error?.response?.data?.message
          );
          toast.error(res?.error?.response?.data?.message);
        }
        getAllComments();
      });
    }
  };

  const handleNavigate = () => {
    navigate("/login");
  };

  const handleEditComment = (index) => {
    setEditIndex(index);
    setEditText(comments[index].comment);
    setInitialText(comments[index].comment);
    setShowEditModal(true);
  };

  const handleEditChange = (e) => {
    setEditText(e.target.value);
  };

  const handleEditSubmit = () => {
    const updatedComments = comments.map((comment, index) =>
      index === editIndex ? { comment: editText } : comment
    );
    const data = {
      comment: editText,
    };
    const adminData = {
      activityType: "edit",
      commentId: comments[editIndex]._id,
      previousContent: initialText,
      updatedContent: editText,
      adminId: userType._id,
    };
    UpdateRatee(
      data,
      `/api/home/updateComment/${comments[editIndex]._id}`
    ).then((res) => {
      console.log("res of update comment = ", res);
      if (res?.data?.status) {
        toast.success(res?.data?.message);

        if (userType.role === "ADMIN") {
          PostRequest(adminData, "/api/home/logActivity").then((res) => {
            console.log("adminactivity added ", res);
          });
        }
      } else {
        console.log(
          "response of review api ==> ",
          res?.error?.response?.data?.message
        );
        toast.error(res?.error?.response?.data?.message);
      }
      getAllComments();
    });
    setComments(updatedComments);
    setShowEditModal(false);
    setEditIndex(null);
    setEditText("");
  };

  const handleDeleteComment = (index, initialText) => {
    const updatedComments = comments.filter((_, i) => i !== index);
    setComments(updatedComments);
    DeleteComment(`/api/home/deleteComment/${comments[index]._id}`).then(
      (res) => {
        const adminData = {
          activityType: "delete",
          commentId: comments[index]._id,
          previousContent: initialText,
          updatedContent: "Comment deleted successfully",
          adminId: userType._id,
        };
        if (userType.role === "ADMIN") {
          PostRequest(adminData, "/api/home/logActivity").then((res) => {
            console.log("adminactivity added ", res);
          });
        }
        getAllComments();
      }
    );
  };

  const getAllComments = () => {
    GetRequest(`/api/home/comments/${forum._id}`).then((res) => {
      // Filter out blocked comments or show all comments for admins and the comment author
      const visibleComments = res.data.data.filter((comment) => {
        if (
          (userType._id === comment.userId && userToken.token !== "") ||
          ((userType.role === "ADMIN" || userType.role === "OPERATOR") &&
            userToken.token !== "")
        ) {
          return true; // Show comment for the author or admin
        } else {
          return !comment.isBlocked; // Show comment if it is not blocked
        }
      });
      // Sort comments in descending order based on updateDate
      const sortedComments = visibleComments.sort(
        (a, b) => b.likes.length - a.likes.length
        // (a, b) => new Date(b.updateDate) - new Date(a.updateDate)
      );
      setComments(sortedComments);
    });
  };

  const handleLikeComment = (commentId) => {
    if (userToken.token != "") {
      const data = {
        userId: userType._id,
      };
      PostRequest(data, `/api/home/addLike/${commentId}`).then((res) => {
        console.log("Liked comment", res);
        getAllComments();
      });
    }
  };

  const handleDislikeComment = (commentId) => {
    if (userToken.token != "") {
      const data = {
        userId: userType._id,
      };
      PostRequest(data, `/api/home/addDislike/${commentId}`).then((res) => {
        console.log("Disliked comment", res);
        getAllComments();
      });
    }
  };

  const handleLikeForum = (forumId) => {
    if (userToken.token === "") {
      return;
    }
    if (forum.userId._id === userType._id) {
      return;
    }
    const isLiked = forum.likes && forum.likes.includes(userType._id);
    if (isLiked) {
      return;
    } else {
      const data = {
        userId: userType._id,
      };
      PostRequest(data, `/api/home/addLikeForum/${forumId}`).then((res) => {
        console.log("Liked forum", res);
        // Update the forum object locally
        const updatedForum = {
          ...forum,
          likes: [...forum.likes, userType._id],
          dislikes: forum.dislikes.filter((id) => id !== userType._id),
        };
        setForum(updatedForum);
      });
    }
  };

  const handleDislikeForum = (forumId) => {
    if (userToken.token === "") {
      return;
    }
    if (forum.userId._id === userType._id) {
      return;
    }
    const isDisliked = forum.dislikes && forum.dislikes.includes(userType._id);
    if (isDisliked) {
      return;
    } else {
      // Update the forum object locally
      const updatedForum = {
        ...forum,
        dislikes: [...forum.dislikes, userType._id],
        likes: forum.likes.filter((id) => id !== userType._id),
      };
      const data = {
        userId: userType._id,
      };
      PostRequest(data, `/api/home/addDislikeForum/${forumId}`).then((res) => {
        console.log("Disliked forum", res);
        setForum(updatedForum);
      });
    }
  };

  const handleReplyComment = (id) => {
    if (replyText && replyText.trim()) {
      const replyData = {
        reply: replyText,
        userName: userType.avatarName,
        userId: userType._id,
      };
      PostRequest(replyData, `/api/home/addReply/${id}`).then((res) => {
        console.log("Replied to comment", res);
        setReplyText("");
        if (res?.data?.status) {
          toast.success(res?.data?.message);
        } else {
          console.log(
            "response of review api ==> ",
            res?.error?.response?.data?.message
          );
          toast.error(res?.error?.response?.data?.message);
        }
        getAllComments();
      });
    }
  };

  const handleToggleReplies = (id) => {
    if (id === showReplies) {
      setShowReplies("");
    } else {
      setShowReplies(id);
    }
  };

  const fetchAdminActivity = async () => {
    const resActivity = await GetRequest(`/api/home/getAdminActivities`);

    if (resActivity.status) {
      setAdminActivity(resActivity.data.data);
    }
  };
  const handleEditReply = (replyId, content) => {
    setEditingReplyId(replyId);
    setReplyContent(content);
    setInitReplyContent(content);
  };

  const handleSaveEditedReply = (commentId, replyId) => {
    // Call your API to save the edited reply here
    const data = {
      reply: replyContent,
    };
    const adminData = {
      activityType: "edit",
      replyId: replyId,
      commentId: commentId,
      previousContent: initReplyContent,
      updatedContent: replyContent,
      adminId: userType._id,
    };
    UpdateRatee(data, `/api/home/editReply/${commentId}/${replyId}`)
      .then((res) => {
        // Update the UI or state as necessary
        setEditingReplyId(null);
        setReplyContent("");
        if (res?.data?.status) {
          toast.success(res?.data?.message);
        } else {
          console.log(
            "response of review api ==> ",
            res?.error?.response?.data?.message
          );
          toast.error(res?.error?.response?.data?.message);
        }
        if (userType.role === "ADMIN") {
          PostRequest(adminData, "/api/home/logActivity").then((res) => {
            console.log("adminactivity added ", res);
          });
        }

        getAllComments();
      })
      .catch((error) => {
        console.error("Failed to update reply:", error);
      });
  };

  const handleDeleteReply = (commentId, replyId, initReplyContent) => {
    // Call your API to delete the reply here
    DeleteComment(`/api/home//deleteReply/${commentId}/${replyId}`)
      .then(() => {
        // Update the UI or state as necessary
        setDeletingReplyId(null);

        const adminData = {
          activityType: "delete",
          replyId: replyId,
          commentId: commentId,
          previousContent: initReplyContent,
          updatedContent: "Reply deleted successfully",
          adminId: userType._id,
        };

        if (userType.role === "ADMIN") {
          PostRequest(adminData, "/api/home/logActivity").then((res) => {
            console.log("adminactivity added ", res);
          });
        }
        getAllComments();
      })
      .catch((error) => {
        console.error("Failed to delete reply:", error);
      });
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    // Get date components
    const year = date.getFullYear();
    const month = date.toLocaleString("en-US", { month: "long" });
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0");

    // Determine AM/PM and convert hour to 12-hour format
    const ampm = hours >= 12 ? "pm" : "am";
    const hour12 = hours % 12 || 12;

    // Add suffix to day (st, nd, rd, th)
    const daySuffix = (day) => {
      if (day > 3 && day < 21) return "th"; // Covers 11th - 20th
      switch (day % 10) {
        case 1:
          return "st";
        case 2:
          return "nd";
        case 3:
          return "rd";
        default:
          return "th";
      }
    };

    // Format the final date string
    return `${month} ${day}${daySuffix(
      day
    )}, ${year}, ${hour12}:${minutes} ${ampm}`;
  };
  const handleEditClick = (item) => {
    navigate(`/create-forum`, { state: { item } });
  };

  const deleteForum = (item) => {
    DeleteComment(`/api/home/deleteForum/${item._id}`).then((res) => {
      console.log("forum deleted ==> ", res);

      const adminData = {
        activityType: "delete",
        forumId: item._id,
        previousContent: `Heading : ${item.heading}, Description : ${item.description}`,
        updatedContent: "Forum deleted successfully",
        adminId: userType._id,
      };
      if (userType.role === "ADMIN") {
        PostRequest(adminData, "/api/home/logActivity").then((res) => {
          console.log("adminActivity ==> ", res);
        });
      }
      navigate("/forum");
    });
  };

  return (
    <Container className="mt-5 vh-100">
      <Row className="justify-content-center">
        <Col md={10}>
          <Card>
            <Card.Header as="h1">
              {forum.heading}
              <span
                style={{
                  fontSize: "0.8em",
                  color: "grey",
                  marginLeft: "10px",
                }}
              >
                (Created By {forum?.userId?.avatarName})
              </span>
            </Card.Header>
            <Card.Body>
              <Card.Text>
                {" "}
                <div
                  style={{
                    fontSize: 16,
                    whiteSpace: "normal", // Allow the text to wrap
                    overflow: "visible", // Show full content
                  }}
                  dangerouslySetInnerHTML={{ __html: forum.description }}
                />
              </Card.Text>
              <Row>
                {(userType.role === "ADMIN" ||
                  userType.role === "OPERATOR" ||
                  userType._id === item?.userId?._id) &&
                  userToken.token !== "" && (
                    <>
                      <Button
                        style={{ width: 100, marginLeft: 5, marginRight: 5 }}
                        variant="secondary"
                        size="sm"
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent the item click handler from firing
                          handleEditClick(item);
                        }}
                      >
                        Edit
                      </Button>
                      <Button
                        style={{ width: 100 }}
                        variant="danger"
                        size="sm"
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent the item click handler from firing
                          deleteForum(item);
                        }}
                      >
                        Delete
                      </Button>
                    </>
                  )}
              </Row>
              {forum.userId._id != userType._id && (
                <div className=" align-items-center mt-1">
                  <Button
                    variant="light"
                    size="sm"
                    className="mt-3"
                    onClick={() => handleLikeForum(forum._id)}
                  >
                    <FaThumbsUp
                      color={
                        forum?.likes && forum?.likes?.includes(userType._id)
                          ? "#007bff"
                          : "#696969"
                      }
                    />
                    (
                    {forum.likes && forum.likes.length ? forum.likes.length : 0}
                    )
                  </Button>
                  <Button
                    variant="light"
                    size="sm"
                    className="mt-3"
                    onClick={() => handleDislikeForum(forum._id)}
                  >
                    <FaThumbsDown
                      color={
                        forum?.dislikes &&
                        forum?.dislikes?.includes(userType._id)
                          ? "#007bff"
                          : "#696969"
                      }
                    />
                    (
                    {forum.dislikes && forum.dislikes.length
                      ? forum.dislikes.length
                      : 0}
                    )
                  </Button>
                </div>
              )}
              {/* Comment Section */}
              <div className="mt-4">
                <h5>Comments</h5>
                <Form
                  onSubmit={
                    userToken.token === ""
                      ? handleNavigate
                      : handleCommentSubmit
                  }
                >
                  <Form.Group controlId="commentText">
                    <Form.Control
                      as="textarea"
                      rows={3}
                      value={commentText}
                      onChange={handleCommentChange}
                      placeholder="Write a comment..."
                    />
                  </Form.Group>

                  <div className="d-flex justify-content-between align-items-center mt-2">
                    <Button variant="primary" type="submit" className="mt-2">
                      Add Comment
                    </Button>
                  </div>
                </Form>
                <ListGroup className="mt-3">
                  {comments.map((comment, index) => {
                    const isCEditedByAdmin = adminActivity.some(
                      (activity) => activity.commentId === comment._id
                    );
                    return (
                      <ListGroup.Item key={index} className="mb-2">
                        <div className="d-flex justify-content-between align-items-center">
                          <div>
                            <strong>{comment.userName || "User Name"}</strong>
                            <span
                              style={{
                                fontSize: "0.8em",
                                color: "grey",
                                marginLeft: "10px",
                              }}
                            >
                              {formatDate(comment.updateDate)}
                            </span>
                          </div>
                          {(userType._id === comment.userId ||
                            userType.role === "ADMIN") &&
                            userToken.token !== "" && (
                              <div>
                                <Button
                                  variant="secondary"
                                  size="sm"
                                  className="me-2"
                                  onClick={() => handleEditComment(index)}
                                >
                                  Edit
                                </Button>
                                <Button
                                  variant="danger"
                                  size="sm"
                                  onClick={() => {
                                    handleDeleteComment(index, comment.comment);
                                    setInitialText(comment.comment);
                                  }}
                                >
                                  Delete
                                </Button>
                              </div>
                            )}
                        </div>
                        <div>
                          {comment.comment}
                          {comment.isBlocked && (
                            <span
                              style={{
                                fontSize: "0.8em",
                                color: "red",
                                marginLeft: "10px",
                              }}
                            >
                              Blocked
                            </span>
                          )}

                          {isCEditedByAdmin && (
                            <div className="text-muted">
                              <small>Edited by admin</small>
                            </div>
                          )}
                        </div>

                        <div className="d-flex justify-content-between align-items-center mt-2">
                          <div>
                            <Button
                              variant="light"
                              size="sm"
                              onClick={() => handleLikeComment(comment._id)}
                            >
                              <FaThumbsUp
                                color={
                                  comment?.likes &&
                                  comment?.likes?.includes(userType._id)
                                    ? "#007bff"
                                    : "#696969"
                                }
                              />
                              (
                              {comment.likes && comment.likes.length
                                ? comment.likes.length
                                : 0}
                              )
                            </Button>
                            <Button
                              variant="light"
                              size="sm"
                              onClick={() => handleDislikeComment(comment._id)}
                            >
                              <FaThumbsDown
                                color={
                                  comment?.dislikes &&
                                  comment?.dislikes?.includes(userType._id)
                                    ? "#007bff"
                                    : "#696969"
                                }
                              />
                              (
                              {comment.dislikes && comment.dislikes.length
                                ? comment.dislikes.length
                                : 0}
                              )
                            </Button>
                            <Button
                              variant="black"
                              size="sm"
                              onClick={() => handleToggleReplies(comment._id)}
                              // onClick={() => handleReplyComment(comment._id)}
                            >
                              <FaReply color="#696969" /> (
                              {comment.replies && comment.replies.length
                                ? comment.replies.length
                                : 0}
                              )
                              {showReplies === comment._id ? (
                                <IoMdArrowDropup size={20} />
                              ) : (
                                <IoMdArrowDropdown size={20} />
                              )}
                            </Button>
                          </div>
                        </div>
                        {/* Replies Section */}
                        {showReplies === comment._id &&
                          comment.replies &&
                          comment.replies.length > 0 && (
                            <ListGroup className="mt-3 ml-3">
                              {comment.replies.map((reply, replyIndex) => {
                                const isREditedByAdmin = adminActivity.some(
                                  (activity) => activity.replyId === reply._id
                                );
                                return (
                                  <ListGroup.Item key={replyIndex}>
                                    {editingReplyId === reply._id ? (
                                      <>
                                        <Form.Group controlId="commentText">
                                          <Form.Control
                                            as="textarea"
                                            rows={3}
                                            value={replyContent}
                                            onChange={(e) =>
                                              setReplyContent(e.target.value)
                                            }
                                          />
                                        </Form.Group>
                                        <Button
                                          variant="secondary"
                                          onClick={() =>
                                            setEditingReplyId(null)
                                          }
                                          className="mt-2 mx-2"
                                        >
                                          Close
                                        </Button>
                                        <Button
                                          variant="primary"
                                          className="mt-2 mx-2"
                                          onClick={() =>
                                            handleSaveEditedReply(
                                              comment._id,
                                              reply._id
                                            )
                                          }
                                        >
                                          Save Changes
                                        </Button>
                                      </>
                                    ) : (
                                      <>
                                        <div className="d-flex justify-content-between align-items-center">
                                          <div>
                                            <strong>
                                              {reply.userName || "User Name"}
                                            </strong>
                                            <span
                                              style={{
                                                fontSize: "0.8em",
                                                color: "grey",
                                                marginLeft: "10px",
                                              }}
                                            >
                                              {formatDate(reply.createDate)}
                                            </span>
                                            <p>{reply.reply}</p>

                                            {isREditedByAdmin && (
                                              <div className="text-muted">
                                                <small>Edited by admin</small>
                                              </div>
                                            )}
                                          </div>
                                          {(userType._id === reply.userId ||
                                            userType.role === "ADMIN") &&
                                            userToken.token !== "" && (
                                              <div>
                                                <Button
                                                  variant="secondary"
                                                  size="sm"
                                                  className="me-2"
                                                  onClick={() =>
                                                    handleEditReply(
                                                      reply._id,
                                                      reply.reply
                                                    )
                                                  }
                                                >
                                                  Edit
                                                </Button>
                                                <Button
                                                  variant="danger"
                                                  size="sm"
                                                  onClick={() => {
                                                    handleDeleteReply(
                                                      comment._id,
                                                      reply._id,
                                                      reply.reply
                                                    );
                                                    setInitReplyContent(
                                                      reply.reply
                                                    );
                                                  }}
                                                >
                                                  Delete
                                                </Button>
                                              </div>
                                            )}
                                          {/* <button
                                      onClick={() =>
                                        handleEditReply(reply._id, reply.reply)
                                      }
                                    >
                                      Edit
                                    </button>
                                    <button
                                      onClick={() =>
                                        handleDeleteReply(
                                          comment._id,
                                          reply._id
                                        )
                                      }
                                    >
                                      Delete
                                    </button> */}
                                        </div>
                                      </>
                                    )}
                                    {/* <div>
                                  <strong>
                                    {reply.userName || "User Name"}
                                  </strong>
                                  <span
                                    style={{
                                      fontSize: "0.8em",
                                      color: "grey",
                                      marginLeft: "10px",
                                    }}
                                  >
                                    {formatDate(reply.createDate)}
                                  </span>
                                </div>
                                <div>{reply.reply}</div> */}
                                  </ListGroup.Item>
                                );
                              })}
                            </ListGroup>
                          )}
                        {showReplies === comment._id && (
                          <ListGroup>
                            <ListGroup.Item>
                              <Form.Group>
                                <Form.Control
                                  as="textarea"
                                  rows={3}
                                  value={replyText}
                                  onChange={(e) => setReplyText(e.target.value)}
                                />
                              </Form.Group>
                              <div className="d-flex justify-content-end mt-2">
                                {isLoading ? (
                                  <Spinner
                                    animation="border"
                                    role="status"
                                    variant="success"
                                  ></Spinner>
                                ) : (
                                  <Button
                                    variant="primary"
                                    onClick={() =>
                                      handleReplyComment(comment._id)
                                    }
                                  >
                                    Reply
                                  </Button>
                                )}
                              </div>
                            </ListGroup.Item>
                          </ListGroup>
                        )}
                      </ListGroup.Item>
                    );
                  })}
                </ListGroup>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Toaster />

      {/* Edit Comment Modal */}
      <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Comment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="editText">
              <Form.Control
                as="textarea"
                rows={3}
                value={editText}
                onChange={handleEditChange}
                placeholder="Edit your comment..."
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowEditModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleEditSubmit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default ForumDetailPage;
