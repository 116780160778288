import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";

const TermsOfUse = () => {
  return (
    <Container className="mt-5">
      <Row>
        <Col md={{ span: 8, offset: 2 }}>
          <Card>
            <Card.Body>
              <h1 className="text-center mb-4">Terms of Use</h1>

              <h5>1. Introduction</h5>
              <p>
                Welcome to Rate MY Admin. By accessing and using our platform,
                you agree to comply with and be bound by these Terms of Use. If
                you do not agree with these terms, you should not use our
                services.
              </p>

              <h5>2. Acceptance of Terms</h5>
              <p>
                By using the Rate MY Admin website, you agree to these Terms of
                Use and our Privacy Policy. You are responsible for ensuring
                that your use complies with all applicable laws.
              </p>

              <h5>3. Eligibility</h5>
              <p>
                You must be at least 18 years old to use Rate MY Admin. By using
                this site, you confirm that you meet these eligibility
                requirements.
              </p>

              <h5>4. User Content (Reviews)</h5>
              <p>
                Users may post reviews and participate in forums. All content
                must be respectful and abide by our guidelines. We reserve the
                right to remove any inappropriate content.
              </p>

              <h5>5. Prohibited Conduct</h5>
              <p>Users may not engage in:</p>
              <ul>
                <li>Defamatory, obscene, or offensive content</li>
                <li>Harassment or abusive behavior</li>
                <li>Any illegal activity</li>
              </ul>

              <h5>6. Intellectual Property</h5>
              <p>
                The intellectual property on Rate MY Admin belongs to us or our
                licensors. You retain ownership of the content you post but
                grant us the right to use it on our platform.
              </p>

              <h5>7. Termination</h5>
              <p>
                We reserve the right to terminate accounts that violate these
                Terms of Use.
              </p>

              <h5>8. Limitation of Liability</h5>
              <p>
                Rate MY Admin is not liable for any damages arising from your
                use of the platform.
              </p>

              <h5>9. Governing Law</h5>
              <p>
                These terms are governed by the laws of [Your Jurisdiction].
              </p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default TermsOfUse;
